import { render, staticRenderFns } from "./NotificationCenter.vue?vue&type=template&id=71f373b8&style=height%3A%20calc(100vh%20-%2064px)%3B%20padding%3A%200px%3B&"
import script from "./NotificationCenter.vue?vue&type=script&lang=ts&"
export * from "./NotificationCenter.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationCenter.vue?vue&type=style&index=0&id=71f373b8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports