var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard_admin"},[_c('div',{staticClass:"locale-changer"},[_c('span',{staticClass:"locale-changer_title"},[_vm._v(_vm._s(_vm.$t(`dashboard_admin.lang`)))]),_c('el-select',{attrs:{"size":'mini'},on:{"change":function($event){return _vm.onChangeLang($event)}},model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}},_vm._l((_vm.langs),function(lang,i){return _c('el-option',{key:`Lang${i}`,attrs:{"value":lang.value,"label":lang.label}})}),1)],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"name_header"},[_vm._v(_vm._s(_vm.$t('dashboard_admin.system_setting'))+" ")]),_c('div',{staticClass:"logo_header"})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_data"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){_vm.addMainTab({
                name: _vm.$t('dashboard_admin.object_editor'),
                componentType: 'ObjectEditor',
              })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.object_editor'))+" ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.object_editor_des'))+" ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_interface"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(_vm._s(_vm.$t('dashboard_admin.menu_editor')))]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.menu_editor_tab'),
                    componentType: 'MenuEditor',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.menu_editor_tab')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.menu_editor_des'))+" ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.dash_editor'),
                    componentType: 'DashboardEditor',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.dash_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.dash_editor_des'))+" ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.css_editor'),
                    componentType: 'CssEditor',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.css_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.css_editor_des'))+" ")])])])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_acess"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){_vm.addMainTab({
                name: _vm.$t('dashboard_admin.access_editor'),
                componentType: 'AccessEditor_v2',
              })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.access_editor'))+" ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.access_editor_des'))+" ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_engineering"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(_vm._s(_vm.$t('dashboard_admin.logic_editor_tab')))]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.logic_editor'),
                    componentType: 'LogicEditor_v2',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.logic_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.logic_editor_des'))+" ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.task_editor'),
                    componentType: 'TaskEditor',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.task_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.task_editor_des'))+" ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.notification_editor'),
                    componentType: 'NotificationEditor',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.notification_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.notification_editor_des'))+" ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.process_editor'),
                    componentType: 'ProcessEditor',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.process_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.process_editor_des'))+" ")])])])])]),_vm._v(" // "),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_source"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.bl_editor'))+" ")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                      name: _vm.$t('dashboard_admin.bl_editor'),
                      componentType: 'BIEditor',
                    })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.bi_editor'))+" ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.bl_editor_des'))+" ")])])])])]),_vm._v(" // "),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_document"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){_vm.addMainTab({
                name: _vm.$t('dashboard_admin.report_editor'),
                componentType: 'ReportEditor',
              })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.report_editor'))+" ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.report_editor_des'))+" ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_map"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){_vm.addMainTab({ name: _vm.$t('dashboard_admin.map_editor'), componentType: 'MapEditor' })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.map_editor'))+" ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.map_editor_des'))+" ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_integration"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(_vm._s(_vm.$t('dashboard_admin.integration')))]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.etl_editor'),
                    componentType: 'EtlEditor',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.etl_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.etl_editor_des'))+" ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.address_editor'),
                    componentType: 'AddressService',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.address_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.address_editor_des'))+" ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.data_editor'),
                    componentType: 'Databus',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.data_editor')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.api_service'),
                    componentType: 'ApiService',
                  })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.api_service'))+" ")]),_vm._v(" - "),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.api_service_des'))+" ")])])])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_sync"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){_vm.addMainTab({
                name: _vm.$t('dashboard_admin.sync_editor'),
                componentType: 'SyncService',
              })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.sync_editor'))+" ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.sync_editor_des'))+" ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_logs"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){_vm.addMainTab({
                name: _vm.$t('dashboard_admin.activity_editor'),
                componentType: 'ActivityService',
              })}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.activity_editor'))+" ")]),_c('div',{staticClass:"menu_text"},[_vm._v(_vm._s(_vm.$t('dashboard_admin.activity_editor_des')))])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_settings"}),_c('div',[_c('div',{staticClass:"menu_header"},[_vm._v(_vm._s(_vm.$t('dashboard_admin.system_editor')))]),_c('div',{staticClass:"menu_text"},[_vm._v(_vm._s(_vm.$t('dashboard_admin.system_editor_dev')))])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_monitoring"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.monitoring'))+" ")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.nginx'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/A4_qnTv7z/web?orgId=1&refresh=10s`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.nginx')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.vm'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/e0ESDrZ7z/hardware?orgId=1&refresh=5s`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.vm')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.postgresql'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/-QLZ8gB7z/postgresql?orgId=1&refresh=5s`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.postgresql')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.kafka'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/HD8pcdM7k/kafka?orgId=1&refresh=1m`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.kafka')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.redis'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/DiEBNrW7z/redis?orgId=1&refresh=30s`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.redis')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.airflow'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/s4WiSDMnk/airflow-cluster?orgId=1&refresh=5s`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.airflow')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.dags'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/XCPzIDM7z/airflow-dag?orgId=1&refresh=5s`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.dags')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.log_service'),
                    componentType: 'LogService',
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.log_service')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$config.project === 'GjiMurmansk'),expression:"$config.project === 'GjiMurmansk'"}],staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.sys_acces'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/grafana/d/Zjv30F04z/hardware-copy?orgId=1`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.sys_acces')))]),_c('div',{staticClass:"menu_text"})])])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_api"}),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(" "+_vm._s(_vm.$t('dashboard_admin.tech_document'))+" ")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.api'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/swagger/index.html`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.api')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: _vm.$t('dashboard_admin.plagin'),
                    componentType: 'IFrame',
                    payload: {
                      url: `${_vm.$config.api}/plugins/index.xhtml`,
                    },
                  })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.plugin')))]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                  name: _vm.$t('dashboard_admin.service'),
                  componentType: 'ChangeLog',
                })}}},[_vm._v(_vm._s(_vm.$t('dashboard_admin.service')))]),_c('div',{staticClass:"menu_text"})])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }