<template>
  <div class="tabs-settings">
    <div class="setting-label">{{ $t('interface_editor.block_settings.position') }} /
      <string-select v-model="settings.position" :options="positions"></string-select>
      <font-awesome-icon :icon="icons.plus" @click="add"></font-awesome-icon>
    </div>

    <draggable v-model="settings.list" group="people">
      <tr
        v-for="(tab, index) in settings.list"
        :key="index"
        :class="{ tab: true, active: editingTabGuid === tab.guid }"
        :style="parentTabStyle(tab)"
      >
        <td style="width: 10%" class="number">
          <b>{{ index + 1 }}.</b>
        </td>

        <td style="width: 70%" @click="editName(tab.guid)">
          <span
            v-if="editingTabGuid !== tab.guid"
            class="label"
          >
            {{ tab.name }}
          </span>

          <el-input
            v-else
            :ref="`editor_${tab.guid}`"
            v-model="tab.name"
            size="mini"
            class="editor"
            @blur="editingTabGuid = undefined"
            @change="editingTabGuid = undefined"
          ></el-input>
        </td>

        <td style="width: 10%" class="tab-settings">
          <el-popover v-model="dialogs.tabs[tab.guid].isShow" popper-class="tab-settings-popper" placement="bottom" width="300" trigger="click">
            <i slot="reference" class="el-icon-setting" style="cursor: pointer;"></i>

            <div role="dialog" class="custom_scrollbar" style="overflow-y: auto; height: 90vh;">
              <header class="setting-label">
                {{ $t('main.fields.alias') }}
              </header>

              <el-input style="padding-left: 10px; padding-bottom: 10px" size="mini" v-model="tab.alias" :placeholder="$t('main.fields.alias')"></el-input>

              <el-checkbox :value="tabSettingsService.getIsDefaultTab(tab.guid)" @change="tabSettingsService.setIsDefaultTab(tab.guid, $event)">{{ $t('interface_editor.default_tab') }}</el-checkbox>

              <div style="margin-top: 10px; padding-bottom: 10px">
                {{ $t('interface_editor.parent_tab') }}
              </div>

              <el-select
                size="mini"
                filterable
                clearable
                :value="tabSettingsService.getParentTabForTree(tab.guid)"
                @change="tabSettingsService.setParentTabForTree(tab.guid, $event)"
              >
                <el-option
                  v-for="(item, index) in listParentTab(tab.guid)"
                  :key="index"
                  :label="item.name"
                  :value="item.guid"
                ></el-option>
              </el-select>

              <header class="setting-label">
                {{ $t('interface_editor.block_settings.condition') }}
              </header>

              <section>
                <header class="setting-label">
                  {{ $t('interface_editor.block_settings.hide_conditions') }}
                </header>

                <EditorConditions
                  :value="tabSettingsService.getHiddenConditions(tab.guid)"
                  @change="tabSettingsService.setHiddenConditions(tab.guid, $event)"
                ></EditorConditions>
              </section>

              <section>
                <header class="setting-label">
                  {{ $t('interface_editor.block_settings.block_conditions') }}
                </header>

                <EditorConditions
                  :value="tabSettingsService.getBlockedConditions(tab.guid)"
                  @change="tabSettingsService.setBlockedConditions(tab.guid, $event)"
                ></EditorConditions>
              </section>

              <section>
                <header class="setting-label">
                  {{ $t('interface_editor.block_settings.style_conditions') }}
                </header>

                <EditorConditions
                  :value="tabSettingsService.getStyledConditions(tab.guid)"
                  @change="tabSettingsService.setStyledConditions(tab.guid, $event)"
                ></EditorConditions>

                <div class="setting-label">
                  {{ $t('interface_editor.block_settings.style_css_tab') }}
                </div>

                <codemirror
                  ref="editorTabStyleByCondition"
                  :value="tabSettingsService.getStyle(tab.guid)"
                  :options="codeEditorOptions"
                  class="block_style_code"
                  style="height: 150px;"
                  @input="tabSettingsService.setStyle(tab.guid, $event)"
                ></codemirror>
              </section>

              <section>
                <header class="setting-label">
                  {{ $t('interface_editor.block_settings.interactive') }}
                </header>

                <EditorButtonAction
                  v-if="dialogs.tabs[tab.guid].isShow"
                  :value="tabSettingsService.getInteractive(tab.guid)"
                  @change="tabSettingsService.setInteractive(tab.guid, $event)"
                ></EditorButtonAction>
              </section>
            </div>
          </el-popover>
        </td>

        <td style="width: 10%" class="delete">
          <i class="el-icon-delete" @click.stop="remove(index, tab.guid)"></i>
        </td>
      </tr>
    </draggable>

    <div class="setting-label">{{ $t('interface_editor.block_settings.style_cont_tab') }}</div>

    <codemirror
      ref="editorContainerStyle"
      v-model="settings.containerStyle"
      class="block_style_code"
      :options="codeEditorOptions"
      style="height: 150px;"
    ></codemirror>

    <div class="setting-label">{{ $t('interface_editor.block_settings.class_tab') }}</div>

    <codemirror
      ref="editorClass"
      v-model="settings.class"
      class="block_style_code px15"
      :options="codeEditorOptions"
      style="height: 15px;"
    ></codemirror>

    <div class="setting-label">{{ $t('interface_editor.block_settings.style_tab') }}</div>

    <codemirror
      ref="editorTabStyle"
      v-model="settings.tabStyle"
      class="block_style_code"
      :options="codeEditorOptions"
      style="height: 150px;"
    ></codemirror>

    <div class="setting-label">{{ $t('interface_editor.block_settings.style_active_tab') }}</div>

    <codemirror
      ref="editorActiveTabStyle"
      v-model="settings.activeTabStyle"
      class="block_style_code"
      :options="codeEditorOptions"
      style="height: 150px;"
    ></codemirror>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import StringSelect from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/StringSelect'
import EditorConditions from '@/components/InterfaceEditor/components/editor/Condition/EditorExpression.vue'
// import EditorConditions from '@/components/InterfaceEditor/components/editor/editor-conditions.vue'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
// import EditorButtonAction from '@/components/InterfaceEditor/components/editor/editor-button-action.vue'
import EditorButtonAction from '@/components/InterfaceEditor/components/editor/ButtonAction/EditorButtonAction'

export default {
  name: 'TabsSettings',

  components: {
    EditorButtonAction,
    StringSelect,
    draggable,
    FontAwesomeIcon,
    EditorConditions
  },

  props: {
    settings: {
      type: Object,
      default: () => { return {} }
    },

    tabSettingsService: {
      type: Object
    }
  },

  data () {
    return {
      editingTabGuid: undefined,
      icons: {
        plus: faPlus
      },
      codeEditorOptions: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true
      },
      dialogs: {
        tabs: {}
      }
    }
  },

  watch: {
    settings: {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      }
    },

    'settings.list': {
      handler (value) {
        if (value.length > 0) {
          this.$set(this.settings, 'use', true)
        } else {
          this.$set(this.settings, 'use', false)
        }

        this.restoreDataStructure()
        this.syncDialogsTabsInstances()
      }
    }
  },

  computed: {
    positions () {
      return [
        { id: 'top', name: this.$t('interface_editor.block_settings.top') },
        { id: 'bottom', name: this.$t('interface_editor.block_settings.bottom') },
        { id: 'left', name: this.$t('interface_editor.block_settings.left') },
        { id: 'right', name: this.$t('interface_editor.block_settings.right') }
      ]
    }
  },

  created () {
    this.restoreDataStructure()
    this.syncDialogsTabsInstances()
  },

  mounted () {
    this.$nextTick(() => {
      this.refreshCodemirrors()
    })
  },

  methods: {
    // список выбора родительской вклакди (исключая выбранной вкладки)
    listParentTab (guid) {
      return this.settings.list.filter(item => item.guid !== guid)
    },

    // Выстраиваем дерево из паддинга
    parentTabStyle (tab) {
      if (!tab || !tab?.guid) {
        return ''
      }
      let paddingLeft = 0
      let parentTab = this.tabSettingsService.tabSettings[tab.guid].parentTabForTree
      while (parentTab) {
        paddingLeft += 10 // Увеличиваем padding-left на 10px за каждый уровень вложенности
        parentTab = this.findParentTab(parentTab)
      }
      return `padding-left: ${paddingLeft}px`
    },

    findParentTab (guid) {
      return this.tabSettingsService.tabSettings[guid]?.parentTabForTree || null
    },

    /**
     * Восстановить целостность структур данных (свойств объектов)
     */
    restoreDataStructure () {
      if (this.tabSettingsService && Array.isArray(this.settings.list)) {
        this.tabSettingsService.createTabSettings(this.settings.list.map(tab => tab.guid))
      }
    },

    /**
     * Синхронизировать экземпляры диалоговых окон в соответствии с каждой вкладкой
     * (На каждую вкладку, будет своё модальное окно со своими настройками)
     */
    syncDialogsTabsInstances () {
      if (!Array.isArray(this.settings.list)) {
        return
      }

      this.$set(this.dialogs, 'tabs', this.settings.list.reduce((obj, tab) => {
        if (!(tab.guid in obj)) {
          obj[tab.guid] = { isShow: false }
        }

        return obj
      }, {}))
    },

    refreshCodemirrors () {
      this.$refs.editorTabStyleByCondition?.codemirror?.refresh()
      this.$refs.editorContainerStyle?.codemirror?.refresh()
      this.$refs.editorClass?.codemirror?.refresh()
      this.$refs.editorTabStyle?.codemirror?.refresh()
      this.$refs.editorActiveTabStyle?.codemirror?.refresh()
    },

    add () {
      if (typeof this.settings.position === 'undefined') {
        this.$set(this.settings, 'position', 'top')
      }

      if (typeof this.settings.list === 'undefined') {
        this.$set(this.settings, 'list', [])
      }
      if (!this.settings?.use) {
        this.$set(this.settings, 'use', true)
      }

      const guid = this.generateGuid()
      this.settings.list.push({
        name: 'Новая вкладка',
        guid
      })

      this.$set(this.dialogs.tabs, guid, {
        isShow: false
      })
    },

    remove (index, guid = '') {
      this.$delete(this.dialogs.tabs, this.settings.list[index].guid)

      this.settings.list.splice(index, 1)
      this.$emit('remove-tab', guid)
    },

    editName (guid) {
      this.editingTabGuid = guid
      this.$nextTick(() => {
        if (this.$refs[`editor_${guid}`]) {
          this.$refs[`editor_${guid}`][0].focus()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tab {
  color: #949FA8;
  padding: 3px;
  display: flex;

  &:hover, &.active {
    outline: 1px solid #E9EBED;
  }

  & .number {
    color: #606266;
  }

  & .label:hover {
    color: #606266;
    cursor: pointer;
    border-bottom: 1px dashed #606266;
  }

  & .delete i:hover {
    color: #606266;
    cursor: pointer;
  }
}

.tabs-settings .el-input--mini .el-input__inner {
  height: 16px;
  line-height: 16px;
  border: none;
  font-size: 14px;
  padding: 0;
}

.setting-label:first-child {
  padding-top: 10px;
}

.setting-label {
  padding-top: 20px;

  & svg {
    margin-right: 5px;
    cursor: pointer;
    color: #949FA8;
    float: right;
  }

  & svg:hover {
    color: #606266;
  }

  & .additional {
    color: #949FA8;
    cursor: pointer;
  }
}
</style>

<style src="../../../main.scss" scoped lang="scss"></style>
