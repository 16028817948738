export default {
  namespaced: true,
  state: {
    notify: '',
    numberUnreadMessages: 0
  },
  mutations: {
    readMsg (state, payload) {
      state.notify = payload
    },
    // устновить кол-во непрочитаннх сообщений
    setNumberUnreadMessages (state, payload) {
      state.numberUnreadMessages = payload
    }
  },
  getters: {
    getNotify (state) {
      return state.notify
    },
    // получить кол-во непрочитаннх сообщений
    getNumberUnreadMessages (state) {
      return state.numberUnreadMessages
    }
  }
}
