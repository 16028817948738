<template>
  <div class="panel">
    <el-tooltip content="Свойства" placement="bottom" effect="light" :open-delay="500">
      <popover-button
        :icon="icons.settings"
        :options="fileOptions"
      >
        <div class="options">
          <div
            v-for="(option, index) in fileOptions"
            class="list_item"
            :key="index"
            @click="getEventBus().$emit('closePopover');getEventBus().$emit(option.event, $event)"
          >
            {{ option.name }}
          </div>
        </div>
      </popover-button>
    </el-tooltip>
    <el-tooltip content="Описание" placement="bottom" effect="light" :open-delay="500">
      <simple-button
        :icon="icons.notes"
        @click="getEventBus().$emit('openNotes')"
      ></simple-button>
    </el-tooltip>
    <div class="button" style="margin-left: auto" @click="getEventBus().$emit('save')">Сохранить</div>
  </div>
</template>

<script>
import { faCog, faPlus, faStickyNote } from '@fortawesome/free-solid-svg-icons'
import PopoverButton from './PopoverButton.vue'
import SimpleButton from '@/services/BPMNEditor/infrastructure/components/Toolbar/SimpleButton.vue'

const FILE_OPTIONS = [
  { name: 'Импортировать', event: 'import' },
  { name: 'Экспортировать', event: 'export' }
]

export default {
  name: 'Toolbar',
  inject: ['getEventBus'],
  components: {
    SimpleButton,
    PopoverButton
  },
  data () {
    return {
      fileOptions: FILE_OPTIONS,
      icons: {
        settings: faCog,
        plus: faPlus,
        notes: faStickyNote
      }
    }
  }
}
</script>
