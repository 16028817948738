<template>
  <div>
    <div class="user-main-edit-panel">
      <div class="wrapper">
        <el-form v-if="userDto != null && profile != null" :rules="createUserRules" :model="userDto" :disabled="isUserLoading" label-position="left">
          <el-row class="user-main-form-wrapper">
            <el-col class="left-side">
              <div class="main-edit-title">{{$locale.access_editor.titles.main_data}}</div>
              <div v-show="!userDto.is_system">
                <div class="avatar-wrapper">
                  <div class="avatar">
                    <fieldset v-show="profile.avatar_id.is_readable">
                      <el-upload
                          class="users upload-demo"
                          ref="upload"
                          action=""
                          list-type="picture-card"
                          :on-change="changeAvatar"
                          :on-remove="removeAvatar"
                          :file-list="avatar"
                          :auto-upload="false"
                          :disabled="!profile.avatar_id.is_editable"
                      >
                        <i class="icon-upload-blue" :title="$locale.access_editor.users_item.download_avatar"></i>
                      </el-upload>
                    </fieldset>
                    <span class="avatar-legend">{{$locale.access_editor.users_item.avatar}}</span>
                  </div>
                </div>
                <div class="user-main-info-wrapper">
                  <div class="fio">
                    <div v-if="!isFIOEdit" class="wrapper">
                      <div v-if="!userDto.id" class="fio-text">
                        <span v-show="profile.surname.is_readable" class="text">{{$locale.access_editor.users_item.surname}}</span>
                        <span v-show="profile.name.is_readable" class="text">{{$locale.access_editor.users_item.name}}</span>
                        <span v-show="profile.midname.is_readable" class="text">{{$locale.access_editor.users_item.midname}}</span>
                      </div>
                      <div v-if="userDto.id" class="fio-text">
                        <span v-show="profile.surname.is_readable" class="text" v-if="userDto.surname">{{userDto.surname}}</span>
                        <span v-show="profile.name.is_readable" class="text" v-if="userDto.name">{{userDto.name}}</span>
                        <span v-show="profile.midname.is_readable" class="text" v-if="userDto.midname">{{userDto.midname}}</span>
                      </div>
                    </div>
                    <div v-if="isFIOEdit" class="wrapper">
                      <el-form-item prop="surname" v-show="profile.surname.is_readable">
                        <el-input v-model="userDto.surname" :readonly="!profile.surname.is_editable" ref="user_surname_input" :placeholder="$locale.access_editor.users_item.surname" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item prop="name" v-show="profile.name.is_readable">
                        <span class="name-required-star" >*</span>
                        <el-input v-model="userDto.name" :readonly="!profile.name.is_editable" :placeholder="$locale.access_editor.users_item.name" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item prop="midname" v-show="profile.midname.is_readable">
                        <el-input v-model="userDto.midname" :readonly="!profile.midname.is_editable"  :placeholder="$locale.access_editor.users_item.midname" autocomplete="off"></el-input>
                      </el-form-item>
                    </div>
                    <el-button :icon="iconFIOEdit" size="small" @click="switchFIOEdit" v-show="profile.name.is_editable"></el-button>
                  </div>
                  <el-form-item prop="role_id" :label="$locale.access_editor.users_item.role" v-show="profile.role_id.is_readable">
                    <el-select
                        v-model="userDto.role_id"
                        :filterable="true"
                        :placeholder="$locale.main.fields.select">
                      <el-option
                          v-for="item in rolesList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="email" :label="$t('access_editor.users_item.email')" v-show="profile.email.is_readable">
                    <el-input v-model="userDto.email" :readonly="!profile.email.is_editable" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="phone" :label="$t('access_editor.users_item.phone')" v-show="profile.phone.is_readable">
                    <el-input v-model="userDto.phone" @input="validatePhone" :readonly="!profile.phone.is_editable" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="telegram_username" :label="$t('access_editor.users_item.telegram_username')" v-show="profile.telegram_username.is_readable">
                    <el-tooltip class="item" effect="dark" content="Используется для привязки учётной записи телеграм к профилю в системе" placement="top">
                      <el-input v-model="userDto.telegram_username" :readonly="!profile.telegram_username.is_editable"></el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item prop="telegram_id" :label="$t('access_editor.users_item.telegram_id')" v-show="profile.telegram_id.is_readable">
                    <el-input v-model="userDto.telegram_id" :readonly="!profile.telegram_id.is_editable"></el-input>
                  </el-form-item>
                  <el-form-item prop="two_factor_auth_type" :label="$locale.access_editor.users_item.two_factor_auth_type" v-show="profile.two_factor_auth_type.is_readable">
                    <el-select
                      :value="userDto.two_factor_auth_type"
                      @input="$set(userDto, 'two_factor_auth_type', $event || null)"
                      :disabled="!profile.two_factor_auth_type.is_editable"
                      clearable>
                      <el-option
                        v-for="(item, index) in twoFactorAuthTypes"
                        :key="index"
                        :label="item"
                        :value="item.toLowerCase()">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="checkbox-group">
                    <el-checkbox v-show="profile.is_admin.is_readable" v-model="userDto.is_admin" :label="$locale.access_editor.users_item.admin" name="is_admin" :disabled="!profile.is_admin.is_editable"></el-checkbox>
                    <el-checkbox v-show="profile.is_system.is_readable" v-model="userDto.is_system" :label="$locale.access_editor.users_item.system" name="is_system" :disabled="!profile.is_system.is_editable" ref="isSystemCheckbox"></el-checkbox>
                    <el-checkbox v-show="profile.is_blocked.is_readable" v-model="userDto.is_blocked" :label="$locale.access_editor.users_item.block" name="is_blocked" :disabled="!profile.is_blocked.is_editable"></el-checkbox>
                    <el-checkbox v-show="profile.is_verified.is_readable" v-model="userDto.is_verified" :label="$locale.access_editor.users_item.verified" name="is_verified" :disabled="!profile.is_verified.is_editable"></el-checkbox>
                  </el-form-item>
                </div>
              </div>
              <div v-show="userDto.is_system" class="is-system">
                <div class="system-wrapper">
                  <el-form-item prop="name" v-show="profile.name.is_readable" :label="$locale.access_editor.users_item.name">
                    <el-input v-model="userDto.name" :readonly="!profile.name.is_editable" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="role_id" v-show="profile.role_id.is_readable" :label="$locale.access_editor.users_item.role">
                    <el-select
                        v-model="userDto.role_id"
                        :filterable="true"
                        :placeholder="$locale.main.fields.select">
                      <el-option
                          v-for="item in rolesList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="checkbox-group">
                    <el-checkbox v-model="userDto.is_system" v-show="profile.is_system.is_readable" :label="$locale.access_editor.users_item.system" name="is_system" :disabled="!profile.is_system.is_editable"></el-checkbox>
                    <el-checkbox v-model="userDto.is_blocked" v-show="profile.is_blocked.is_readable" :label="$locale.access_editor.users_item.block" name="is_blocked" :disabled="!profile.is_blocked.is_editable"></el-checkbox>
                    <el-checkbox v-model="userDto.is_verified" v-show="profile.is_verified.is_readable" :label="$locale.access_editor.users_item.verified" name="is_verified" :disabled="!profile.is_verified.is_editable"></el-checkbox>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col class="right-side">
              <div v-if="!userDto.is_system">
                <el-row>
                  <el-col>
                    <el-form-item prop="login" class="login" v-show="profile.login.is_readable" :label="$locale.access_editor.users_item.login">
                      <el-tooltip class="item" effect="dark" content="Не менее пяти символов" placement="top">
                        <el-input v-model="userDto.login" autocomplete="off" :readonly="!profile.login.is_editable"></el-input>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item prop="password" v-show="profile.password.is_readable" :label="$locale.access_editor.users_item.password">
                      <el-input v-model="userDto.password" class="password" :readonly="passwordControl['inputLocked']" autocomplete="off" @input="passwordCheck"></el-input>
                      <el-tooltip class="item" effect="dark" :content="$locale.access_editor.users_item.change_password" placement="bottom">
                        <el-button icon="icon-lock" class="password-lock-button" size="small" @click="switchPasswordLocker"></el-button>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="passwordControl['isValid'] === false">
                    <ul class="password-control-list">
                      <li v-if="passwordControl['lengthValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordLength}}: {{passwordRules['length']['min']}}-{{passwordRules['length']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['uppercaseValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordUppercase}}: {{passwordRules['uppercase']['min']}}-{{passwordRules['uppercase']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['lowercaseValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordLowercase}}: {{passwordRules['lowercase']['min']}}-{{passwordRules['lowercase']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['numbersValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordNumbers}}: {{passwordRules['numbers']['min']}}-{{passwordRules['numbers']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['symbolsValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordSpecChars}}: {{passwordRules['symbols']['min']}}-{{passwordRules['symbols']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                    </ul>
                  </el-col>
                  <el-col v-if="passwordControl['isValid'] === false">
                    <el-button icon="el-icon-refresh" class="password-generate-button" @click="generatePassword">{{$locale.access_editor.users_item.generate_password}}</el-button>
                  </el-col>
                </el-row>
              </div>
              <div v-if="userDto.is_system" v-show="profile.is_system.is_readable">
                <div class="is-system">
                  <el-col>
                    <el-form-item v-if="userDto" prop="api_key" :label="$locale.access_editor.users_item.api_key">
                      <el-input v-model="userDto.api_key" readonly type="textarea" autocomplete="off" class="api-key"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-button icon="el-icon-refresh" class="api-generate-button" @click="generateApiKey">{{$locale.access_editor.users_item.generate_api_key}}</el-button>
                  </el-col>
                  <el-col>
                    <fieldset v-if="userDto.is_system" style="max-height: 200px; overflow-y: auto">
                      <legend>{{$locale.access_editor.users_item.system_ips}}</legend>
                      <el-tag
                          class="ip-tag"
                          :key="ip"
                          v-for="ip in userDto.system_ips"
                          closable
                          :disable-transitions="false"
                          @close="handleRemoveIp(ip)">
                        {{ip}}
                      </el-tag>
                      <el-input
                          class="input-new-ip"
                          v-if="inputIpVisible"
                          v-model="inputIpValue"
                          ref="saveIpInput"
                          size="mini"
                          @keyup.enter.native="handleIpInputConfirm"
                          @blur="handleIpInputConfirm"
                      >
                      </el-input>
                      <el-button v-else class="button-new-ip" size="small" @click="showIpInput">+ IP</el-button>
                    </fieldset>
                  </el-col>
                  <el-col>
                    <fieldset v-if="userDto.is_system" style="max-height: 200px; overflow-y: auto">
                      <legend>{{$locale.access_editor.users_item.system_urls}}</legend>
                      <el-tag
                          class="ip-tag"
                          :key="url"
                          v-for="url in userDto.system_urls"
                          closable
                          :disable-transitions="false"
                          @close="handleRemoveUrl(url)">
                        {{url}}
                      </el-tag>
                      <el-input
                          class="input-new-ip"
                          v-if="inputUrlVisible"
                          v-model="inputUrlValue"
                          ref="saveUrlInput"
                          size="mini"
                          @keyup.enter.native="handleUrlInputConfirm"
                          @blur="handleUrlInputConfirm"
                      >
                      </el-input>
                      <el-button v-else class="button-new-ip" size="small" @click="showUrlInput">+ Url</el-button>
                    </fieldset>
                  </el-col>
                </div>
              </div>
              <span class="dialog-footer">
                <el-button @click="returnToUserList">{{$locale.main.button.back}}</el-button>
                <el-button class="save-button" :disabled="saveButtonLocker()" @click="saveUser">{{$locale.main.button.save}}</el-button>
              </span>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="user-card-edit-panel custom_scrollbar">
      <div class="left-side" style="height: 100%">
        <div class="main-edit-title">{{$locale.access_editor.titles.extended_data}}</div>
        <CardsWrapper
            v-loading="isUserLoading"
            ref="cardsWrapper"
            :key="userKey"
            :prevent-user-card="true"
            :show-back-breadcrumb="false"
            :show-block-btn="false"
            :show-breadcrumb-empty='false'
          ></CardsWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import User, { UserDTO } from '@/services/AccessEditor/domain/model/User/User'
import UsersQuery from '@/services/AccessEditor/application/query/UsersQuery'
import UserAvatarQuery from '@/services/AccessEditor/application/query/UserAvatarQuery'
import UserCreateCommand from '@/services/AccessEditor/application/command/UserCreateCommand'
import UserUpdateCommand from '@/services/AccessEditor/application/command/UserUpdateCommand'
import UserPasswordQuery from '@/services/AccessEditor/application/query/UserPasswordQuery'
import UserPasswordRulesQuery from '@/services/AccessEditor/application/query/UserPasswordRulesQuery'
import UserPasswordCheckQuery from '@/services/AccessEditor/application/query/UserPasswordCheckQuery'
import RoleProfileQuery from '@/services/AccessEditor/application/query/RoleProfileQuery'
import UserApiKeyQuery from '@/services/AccessEditor/application/query/UserApiKeyQuery'
import Registry from '@/components/Registry/Models/Registry'
import RegistryCard from '@/components/RegistryCard/index.vue'
import CardsWrapper from '@/components/Registry/CardsWrapper.vue'
import UserByGuidQuery from "@/services/AccessEditor/application/query/UserByGuidQuery";

export default {
  name: 'UserMainEditPanel',
  components: {
    RegistryCard,
    Registry,
    CardsWrapper
  },
  props: {
    rolesList: {
      type: Array,
      required: true
    },
    cardParams: {
      type: Object,
      required: true
    },
    preventReturn: {
      type: Boolean,
      default: false
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      twoFactorAuthTypes: ['SMS', 'TOTP'],
      currentUser: null,
      userDto: null,
      avatar: [],
      createUserRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        role_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        login: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
      passwordRules: [],
      passwordControl: {
        'isValid': true,
        'locked': true,
        'inputLocked': true,
        'lengthValid': false,
        'uppercaseValid': false,
        'lowercaseValid': false,
        'numbersValid': false,
        'symbolsValid': false
      },
      isSystemUser: null,
      inputIpVisible: false,
      inputIpValue: '',
      inputUrlVisible: false,
      inputUrlValue: '',
      profile: null,
      isFIOEdit: false,
      iconFIOEdit: 'icon-edit-blue',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  created: function () {
    let currentUser = this.$store.getters['Authorization/userId']
    if (Number.isInteger(currentUser)) {
      this.getQueryBus().execute(
        new UsersQuery({
          id: currentUser,
          limit: 1,
          offset: 0
        })
      ).then(data => {
          this.currentUser = data[0]
          this.loadProfile()
      })
    }
    this.getEventBus().$on('editUserEvent', (data) => {
      this.userDto = data
      if (this.userDto != null) {
        if (this.userDto.id == null) {
          this.setFocus()
          this.userDto.is_verified = true;
        } else {
          this.isFIOEdit = false
        }
      }
      this.userDto.password = ''
      this.passwordControl['locked'] = true
      this.passwordControl['inputLocked'] = true
      this.getAvatar()
      if (Object.keys(this.cardParams).length) {
        this.$nextTick(() => {
          this.$refs.cardsWrapper.openRegistryCard({
            registryId: this.cardParams.registryId,
            cardId: this.cardParams.cardId,
            cardName: '',
            recordId: this.userDto.id
          })
        })
      }
    })
  },
  watch: {
    userDto: {
      handler: async function (curDto) {
        this.isSystemUser = curDto !== null ? curDto.is_system : null
      },
      deep: true
    },
    getSaveUserError: function (err) {
      if (err.code === 'duplicate_key_violation_exception') {
        if (err.message.includes('Key login =')) {
          this.$message({
            message: this.$locale.access_editor.users_item.duplicate_login,
            type: 'warning'
          })
        }
      }
    }
  },
  computed: {
    isUserLoading () {
      return this.$store.getters['User/isLoading']
    },
    getSaveUserError () {
      return this.$store.getters['User/getError']
    },
    userKey () {
      return this.userDto?.id || 0
    }
  },
  methods: {
    returnToUserList () {
      if (this.preventReturn) {
        this.$emit('cancelChanges')
        return false
      }
      this.getEventBus().$emit('returnToUserListEvent', {
        'list': true,
        'edit': false
      })
      this.passwordControl['inputLocked'] = true
      this.passwordControl['isValid'] = true
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = []
      }
      this.userDto = null
    },
    getRolesList () {
      return this.rolesList
    },
    async loadProfile () {
      let roleId = 0
      if (this.currentUser !== null) {
        roleId = this.currentUser.role_id
      }
      await this.getQueryBus().execute(
        new RoleProfileQuery(roleId)
      ).then(data => {
        if (data.length > 0) {
          this.profile = {}
          for (let i = 0; i < data.length; i++) {
            this.profile[data[i].user_field_id] = {
              is_readable: this.currentUser.is_admin ? true : data[i].is_readable,
              is_editable: this.currentUser.is_admin ? true : data[i].is_editable
            }
          }
        }
      })
    },
    switchFIOEdit () {
      this.isFIOEdit = !this.isFIOEdit
      this.isFIOEdit ? this.iconFIOEdit = 'icon-ok-green' : this.iconFIOEdit = 'icon-edit-blue'
    },
    setFocus () {
      this.isFIOEdit = true
      this.$nextTick(() => {
        this.$refs.user_surname_input?.focus()
      })
    },
    async getAvatar () {
      if (this.userDto != null) {
        let avatarId = this.userDto.avatar_id
        if (avatarId != null) {
          this.avatar = []
          await this.getQueryBus().execute(
            new UserAvatarQuery(avatarId)
          ).then(data => {
            this.avatar.push({
              name: this.getFileName(data),
              url: `${this.$config.api}/files/${this.getFilePath(data)}`
            })
          })
        }
      }
    },
    changeAvatar (file) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
        this.removeAvatar()
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1)
      }
      this.userDto.avatar = this.$refs.upload.uploadFiles[0].raw
      this.userDto.is_avatar_deleted = false
    },
    removeAvatar () {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = []
      }
      if (this.userDto !== null) {
        this.userDto.avatar = null
        this.userDto.is_avatar_deleted = true
      }
      this.avatar = []
    },
    switchPasswordLocker () {
      if (this.profile.password.is_editable) {
        this.passwordControl['locked'] = !this.passwordControl['locked']
        this.passwordControl['inputLocked'] = !this.passwordControl['inputLocked']
        if (typeof this.passwordRules['length']['min'] === 'undefined' || this.passwordRules['length']['min'] == null) {
          this.getPasswordRules()
            .then(data => {
              this.passwordControl['isValid'] = !this.passwordControl['isValid']
            })
        }
        else {
          this.passwordControl['isValid'] = !this.passwordControl['isValid']
        }
      }
    },
    async getPasswordRules () {
      await this.getQueryBus().execute(
        new UserPasswordRulesQuery()
      ).then(data => {
        this.passwordRules = data.rules
      })
    },
    async generatePassword () {
      await this.getQueryBus().execute(
        new UserPasswordQuery()
      ).then(data => {
        this.userDto.password = data.password
        this.passwordCheck()
      })
    },
    async passwordCheck () {
      await this.getQueryBus().execute(
        new UserPasswordCheckQuery(this.userDto.password)
      ).then(data => {
        this.passwordControl['isValid'] = data['valid']
        this.passwordControl['lengthValid'] = data['lengthValid']
        this.passwordControl['uppercaseValid'] = data['uppercaseValid']
        this.passwordControl['lowercaseValid'] = data['lowercaseValid']
        this.passwordControl['numbersValid'] = data['numbersValid']
        this.passwordControl['symbolsValid'] = data['symbolsValid']
      })
    },
    saveButtonLocker () {
      if (this.userDto === null || typeof this.userDto === 'undefined') {
        return true
      }
      if (this.isUserLoading) {
        return true
      }
      if (this.userDto.is_system === true) {
        return false
      }
      if (!this.passwordControl['isValid']) {
        return true
      }
      if (typeof this.userDto.login === 'undefined' || this.userDto.login === null) {
        return true
      }
      if (this.userDto.login.length < 5 || this.userDto.login.length > 128) {
        return true
      }
      if (typeof this.userDto.name === 'undefined' || this.userDto.name === null) {
        return true
      }
      if (this.userDto.name.length < 1 || this.userDto.name.length > 255) {
        return true
      }
      if (typeof this.userDto.role_id === 'undefined' || this.userDto.role_id === null) {
        return true
      }
      if (typeof this.userDto.id == 'undefined' && this.userDto.password == "") {
        return true
      }
      return false
    },
    async generateApiKey () {
      if (this.userDto.id === null) {
        this.$message({
          message: this.$locale.access_editor.users_item.user_must_be_saved,
          type: 'warning'
        })
        this.userDto.api_key = ''
        return
      }
      if (this.userDto.role_id === null) {
        this.$message({
          message: this.$locale.access_editor.users_item.user_role_must_be_set,
          type: 'warning'
        })
        this.userDto.api_key = ''
        return
      }
      if (this.userDto.system_ips.length === 0) {
        this.userDto.api_key = ''
        if (this.userDto.id !== 0) {
          this.$message({
            message: this.$locale.access_editor.users_item.system_ips_must_be_set,
            type: 'warning'
          })
          return
        }
      }
      await this.getQueryBus().execute(
        new UserApiKeyQuery(
          this.userDto.id,
          this.userDto.role_id,
          this.userDto.system_ips,
          this.userDto.system_urls
        )
      ).then(data => {
        this.userDto.api_key = data.api_key
      })
    },
    handleRemoveIp (ip) {
      this.userDto.system_ips.splice(this.userDto.system_ips.indexOf(ip), 1)
    },
    handleIpInputConfirm () {
      let inputIpValue = this.inputIpValue
      if (inputIpValue) {
        this.userDto.system_ips.push(inputIpValue)
      }
      this.inputIpVisible = false
      this.inputIpValue = ''
    },
    showIpInput () {
      this.inputIpVisible = true
      this.$nextTick(() => {
        this.$refs.saveIpInput.$refs.input.focus()
      })
    },
    handleRemoveUrl (url) {
      this.userDto.system_urls.splice(this.userDto.system_urls.indexOf(url), 1)
    },
    handleUrlInputConfirm () {
      let inputUrlValue = this.inputUrlValue
      if (inputUrlValue) {
        this.userDto.system_urls.push(inputUrlValue)
      }
      this.inputUrlVisible = false
      this.inputUrlValue = ''
    },
    showUrlInput () {
      this.inputUrlVisible = true
      this.$nextTick(() => {
        this.$refs.saveUrlInput.$refs.input.focus()
      })
    },
    saveUser () {
      if (this.isEmailValid()) {
        //this.validatePhone()
        let user = User.create(this.userDto)
        if (user.getId() == null) {
          this.getCommandBus().execute(
            new UserCreateCommand(
              user.getRoleId(),
              user.getName(),
              user.getMidname(),
              user.getSurname(),
              user.getAvatar(),
              user.getLogin(),
              user.getPassword(),
              user.getEmail(),
              user.getIsAdmin(),
              user.getIsBlocked(),
              user.getIsSystem(),
              user.getApiKey(),
              user.getSystemIps(),
              user.getPhone(),
              user.getTwoFactorAuthType(),
              user.getTelegramId(),
              user.getTelegramUsername(),
              user.getIsVerified(),
              user.getSystemUrls()
            )
          ).then(async (location) => {
            const userGuid = location.split('/').pop()
            if (userGuid) {
              return this.getQueryBus().execute(
                new UserByGuidQuery(userGuid)
              )
            }
          }).then((user) => {
            let cards = this.$refs.cardsWrapper.$refs.cards.$refs.registryCard
            return cards[cards.length - 1].saveRecord({ force_record_id: user.id })
          }).then(() => {
            this.getEventBus().$emit('userIsCreated')
            //this.returnToUserList()
          })
          if (user.isSystem === true) {
            this.returnToUserList()
          }
        }
        else {
          this.getCommandBus().execute(
            new UserUpdateCommand(
              user.getGuid(),
              user.getRoleId(),
              user.getName(),
              user.getMidname(),
              user.getSurname(),
              user.getAvatar(),
              user.getIsAvatarDeleted(),
              user.getLogin(),
              user.getPassword(),
              user.getEmail(),
              user.getIsAdmin(),
              user.getIsBlocked(),
              user.getIsSystem(),
              user.getApiKey(),
              user.getSystemIps(),
              user.getPhone(),
              user.getTwoFactorAuthType(),
              user.getTelegramId(),
              user.getTelegramUsername(),
              user.getIsVerified(),
              user.getSystemUrls()
            )
          ).then(data => {
            if (user.getIsSystem()) {
              this.getEventBus().$emit('userIsUpdated', this.userDto)
              this.returnToUserList()
            }
            else {
              // this.$refs.user_registry_card_data.saveRecord().then(data => {
              let cards = this.$refs.cardsWrapper.$refs.cards.$refs.registryCard
              cards[cards.length - 1].saveRecord().then(data => {
                this.getEventBus().$emit('userIsUpdated', this.userDto)
                this.returnToUserList()
              })
            }
          })
        }
      }
      else {
        this.$message({
          message: this.$locale.access_editor.users_item.email_must_be_valid,
          type: 'warning'
        })
      }
    },
    isEmailValid () {
      if (typeof this.userDto.email == 'undefined') {
        this.userDto.email = null
      }
      if (this.userDto.email != null) {
        if ((this.userDto.email).trim() == '') {
          this.userDto.email = null
        }
      }
      return (this.userDto.email === null) ? true : !!(this.reg.test(this.userDto.email))
    },
    validatePhone () {
      if (this.userDto.phone != null) {
        this.userDto.phone = ((this.userDto.phone).toString().trim()).replace(/\D/g, "")
        if (this.userDto.phone == "") {
          this.userDto.phone = null
        }
      }
    }
  }
}
</script>
