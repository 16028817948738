<template>
  <div>
    <div class="setting-label">{{$t('interface_editor.block_settings.source')}} / <string-select
        v-model="additionalData.sourceType"
        :options="sourceTypes"
      ></string-select>
    </div>

    <registry-select-tree
      v-if="additionalData.sourceType === 'Registry'"
      v-model="additionalData.sourceId"
      type="registry"
      value-as="number"
    ></registry-select-tree>

    <div class="block" v-if="additionalData.sourceType === 'Registry'">
      <div class="setting-label" style="display: inline-block">Оптимизировать</div>
      <el-tooltip style="margin-left: 10px" content="Загружать только необходимые аттрибуты" placement="top" effect="light" :open-delay="500">
        <el-icon class="el-icon-question"></el-icon>
      </el-tooltip>
      <el-input
        v-model="additionalData.optimizeLoading"
        size="mini"
      ></el-input>
    </div>

    <el-form-item v-if="additionalData.sourceType === 'extended_object'">
      <span class="property_label">{{ $t('interface_editor.component_editor.table.extended_object') }}</span>
      <el-select
        :value="additionalData.sourceId"
        filterable
        @change="$set(additionalData ,'sourceId', $event)"
      >
        <el-option
          v-for="(item, index) in extended_objects"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-if="additionalData.sourceType === 'Query'">
      <span class="property_label">{{ $t('interface_editor.component_editor.table.request') }}</span>
      <el-select
        :value="additionalData.sourceId"
        filterable
        @change="$set(additionalData ,'sourceId', $event)"
      >
        <el-option
          v-for="(item, index) in queries"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>

      <el-form-item v-if="additionalData && additionalData.sourceType === 'Query'">
        <editor-filters
            title="Параметры запроса"
            :value="additionalData.queryParameters"
            :isFieldConstantAndUser="true"
            @change="$set(additionalData ,'queryParameters', $event)"
            type="parameter"
        ></editor-filters>
      </el-form-item>
    </el-form-item>
    <br>

    <el-form-item style="display: flex">
      <el-checkbox v-model="additionalData.isAttrsSelectedFromSource">
      </el-checkbox>
      <span class="setting-label">{{$t('interface_editor.block_settings.is_use_attrs_from_source')}}</span>
    </el-form-item>

    <div class="setting-label">{{$t('interface_editor.block_settings.columns')}} <el-input-number
        v-model="settings.columns"
        :min="0"
        controls-position="right"
        size="mini"
        style="float: right"
      ></el-input-number>
    </div>

    <div class="setting-label" style="display: flex; align-items: center">
      <span style="display: inline-block;flex: 1">{{$t('interface_editor.block_settings.count_page')}}</span>
      <el-input-number
        v-model="additionalData.limit"
        :min="0"
        controls-position="right"
        size="mini"
        style="float: right"
      ></el-input-number>
    </div>
    <el-button :disabled="!additionalData.limit" @click="addPagination" type="text">{{$t('interface_editor.block_settings.add_pagination')}}</el-button>

    <div class="setting-label">{{$t('interface_editor.block_settings.margins')}}</div>
    <div class="sizes">
      <div v-for="(item, index) in margins" :key="index">
        <span class="name">{{ item.name }}</span>
        <span class="data">
          <span class="value" v-if="editSizeType !== item.id" @click="editMargin(item.id)">
            {{ settings[item.id].value || 0 }}
          </span>
          <el-input size="mini"
                    v-model="settings[item.id].value"
                    @blur="editSizeType = undefined"
                    @change="editSizeType = undefined"
                    v-else
                    class="editor"
                    :ref="`editor_${item.id}`"
          ></el-input>
          <span class="type" @click="changeMarginType(item.id)">{{settings[item.id].type || 'px'}}</span>
        </span>
      </div>
    </div>

    <div class="setting-label">{{$t('interface_editor.block_settings.filter')}}</div>
    <EditorFilters
      v-model="additionalData.filters"
      :options="{ showEqualsTypes: true, showXrefOption: true, isShowLabel: false, tooltip: 'Фильтры работают по логике <b>И</b><br><br>Как сделать логику <b>ИЛИ</b> (поиск по нескольким полям в целевом реестре)?<br><b>Чекбокс</b> - сделать один с нескольким вариантами<br><b>Строка</b> - создать формульное поле с объединением значений нужных полей и выбрать тип сравнения полей <b>Поиск</b> для поиска LIKE' }"
      :show-equals-types="true"
      :show-xref-option="true"
    ></EditorFilters>

    <div class="setting-label">{{$t('interface_editor.block_settings.state')}}</div>
    <EditorStates
      v-model="additionalData.stateId"
      :options="statesOptions"
    ></EditorStates>
  </div>
</template>

<script>
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import StringSelect from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/StringSelect'
import EditorFilters from '@/components/InterfaceEditor/components/editor/editor-filters.vue'
import EditorStates from '@/components/InterfaceEditor/components/editor/editor-states.vue'
import EditorAnalyticTableSource from '@/components/InterfaceEditor/components/editor/editor-analytic-table-source.vue'

export default {
  name: 'ReplicationSettings',
  components: { EditorAnalyticTableSource, StringSelect, RegistrySelectTree, EditorFilters, EditorStates },
  inject: {
    getEventBus: {
      default: () => {}
    }
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {
          columns: 1,
          horizontalMargin: {
            type: 'px',
            value: 0
          },
          verticalMargin: {
            type: 'px',
            value: 0
          }
        }
      }
    }
  },
  data () {
    return {
      margins: [
        { id: 'horizontalMargin', name: 'Left' },
        { id: 'verticalMargin', name: 'Top' }
      ],
      editSizeType: undefined,
      additionalData: this.settings.additionalData || {
        sourceType: 'None',
        sourceId: null,
        filters: [],
        limit: 10,
        stateId: null
      },
      extended_objects: [],
      queries: []
    }
  },
  watch: {
    settings: {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      }
    },
    additionalData: {
      deep: true,
      handler (value) {
        this.$set(this.settings, 'additionalData', value)
      }
    },
    'additionalData.sourceType': {
      async handler () {
        this.additionalData.sourceId = null
      }
    }
  },
  created () {
    this.restoreInvalidDataStructure()
  },
  async mounted () {
    let data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
    let queries = await this.$http.get(`${this.$config.api}/bieditor/queries`)
    this.extended_objects = data.data
    this.queries = queries.data
  },
  computed: {
    statesOptions () {
      return { registryId: this.additionalData.sourceId }
    },
    sourceTypes () {
      return [
        { id: 'None', name: this.$t('interface_editor.block_settings.absent') },
        { id: 'Registry', name: this.$t('interface_editor.block_settings.registry') },
        { id: 'extended_object', name: this.$t('interface_editor.block_settings.extended_table') },
        { id: 'Query', name: this.$t('interface_editor.block_settings.query') }
      ]
    }
  },
  methods: {
    restoreInvalidDataStructure () {
      if (this.settings.additionalData) {
        if (!('limit' in this.settings.additionalData)) {
          this.settings.additionalData.limit = 10
        }
      }
    },
    addPagination () {
      this.getEventBus().$emit('addPaginationBlock', { limit: this.additionalData.limit, settings: this.additionalData })
    },
    editMargin (type) {
      this.editSizeType = type
      this.$nextTick(() => {
        if (this.$refs[`editor_${type}`]) {
          this.$refs[`editor_${type}`][0].focus()
        }
      })
    },
    changeMarginType (type) {
      if (this.settings[type].type) {
        this.settings[type].type = this.settings[type].type === 'px' ? '%' : 'px'
      } else {
        this.settings[type].type = '%'
      }
    }
  }
}
</script>

<style scoped>

</style>
<style src="../../../main.scss" scoped lang="scss"></style>
