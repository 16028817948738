
import { Nullable } from '@/core/domain/type/types'

/**
 @param context - контекст компонента (this),
 @param registryId - id реестра,
 @param recordId - id записи
*/
export const getCardId = async (context: any, { registryId, recordId }:
  { registryId: number, recordId: Nullable<number> }): Promise<object> => {
  let url = `${context.$config.api}/registryservice/registry/${registryId}/card`
  if (recordId) {
    url = `${context.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
  }

  return (await context.$http.get(url)).data[0]
}

/**
 проверка на число
 @param n - Любая переменная
*/
export const isNumeric = (n: any): boolean => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}
/**
 * Является ли переменная объектом
*/
export function isObject (obj:any): boolean {
  return Object.prototype.toString.call(obj) === '[object Object]'
}
/**
 * Является ли переменная массивом
*/
export function isArray (arr:any): boolean {
  return Object.prototype.toString.call(arr) === '[object Array]'
}
/**
 * Переменные одинакового типа
*/
export function isSameType (item1:any, item2:any): boolean {
  return Object.prototype.toString.call(item1) === Object.prototype.toString.call(item2)
}
/**
 * Глубокое сравнение любых данных
 * deepCompare(null, {}) --> false;
 * deepCompare([1, 2, 3], [1, 2, 3] --> true;
 * deepCompare({a: 1, c: null, b: 2}, {a: 1, b: 2, c: null}) --> true
 */
export function deepCompare (obj1:any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true
  }

  if (
    (!isObject(obj1) && !isArray(obj1)) ||
    (!isObject(obj2) && !isArray(obj2))
  ) {
    return false
  }

  if (
    !isSameType(obj1, obj2) ||
    Object.keys(obj1).length !== Object.keys(obj2).length
  ) {
    return false
  }

  for (let key of Object.keys(obj1)) {
    if (!obj2.hasOwnProperty(key)) {
      return false
    }

    if (!deepCompare(obj1[key], obj2[key])) {
      return false
    }
  }
  return true
};

export default class _localStorage {
  public static clearExcept (keys: string[]) {
    Object.keys(localStorage).forEach(key => {
      if (!keys.includes(key)) {
        localStorage.removeItem(key)
      }
    })
  }
}

export const generateGuid = () => {
  let d = new Date().getTime()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now()
  }
  return 'c_' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

/**
 * Валидный JSON
 *
 * @param text
 */
export const isValidJson = function (text: any): boolean {
  if (typeof text !== 'string') {
    return false
  }

  try {
    JSON.parse(text)

    return true
  } catch (e) {
    return false
  }
}

export const jsonParse = function (text: any): any {
  if (typeof text !== 'string') {
    return text
  }

  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}

export const isPgArray = function (text: any): boolean {
  if (typeof text !== 'string') {
    return false
  }

  return (/\{.*\}/g).test(text)
}

export const pgArrayParse = function (text: string): any[] {
  try {
    return JSON.parse(text.replaceAll('{', '[').replaceAll('}', ']'))
  } catch {
    return []
  }
}

export function cloneObject<T> (value: T): T {
  return JSON.parse(
    JSON.stringify(value)
  )
}
