<template>
  <div class="dashboard_admin">
    <div class="locale-changer">
      <span class="locale-changer_title" >{{ $t(`dashboard_admin.lang`) }}</span>
      <el-select v-model="$i18n.locale" @change="onChangeLang($event)" :size="'mini'">
        <el-option
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
          :value="lang.value"
          :label="lang.label">
        </el-option>
      </el-select>
    </div>
    <div class="wrapper">
      <div class="header">
        <div class="name_header">{{ $t('dashboard_admin.system_setting') }}
        </div>
        <div class="logo_header"></div>
      </div>
      <!-- <el-scrollbar class="container" wrap-style="overflow-x:hidden;"> -->
      <div class="container">
        <div class="menu">
          <div class="icon icon_data"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: $t('dashboard_admin.object_editor'),
                  componentType: 'ObjectEditor',
                })
              "
            >
              {{$t('dashboard_admin.object_editor')}}
            </div>
            <div class="menu_text">
              {{$t('dashboard_admin.object_editor_des')}}
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_interface"></div>
          <div>
            <div class="menu_header non_underline">{{ $t('dashboard_admin.menu_editor') }}</div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.menu_editor_tab'),
                      componentType: 'MenuEditor',
                    })
                  "
                  >{{ $t('dashboard_admin.menu_editor_tab') }}</span
                >
                <div class="menu_text">
                  {{ $t('dashboard_admin.menu_editor_des') }}
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.dash_editor'),
                      componentType: 'DashboardEditor',
                    })
                  "
                  >{{ $t('dashboard_admin.dash_editor') }}</span
                >
                <div class="menu_text">
                  {{ $t('dashboard_admin.dash_editor_des') }}
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.css_editor'),
                      componentType: 'CssEditor',
                    })
                  "
                >{{ $t('dashboard_admin.css_editor') }}</span
                >
                <div class="menu_text">
                  {{ $t('dashboard_admin.css_editor_des') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_acess"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: $t('dashboard_admin.access_editor'),
                  componentType: 'AccessEditor_v2',
                })
              "
            >
              {{$t('dashboard_admin.access_editor')}}
            </div>
            <div class="menu_text">
                {{$t('dashboard_admin.access_editor_des')}}
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_engineering"></div>
          <div>
            <div class="menu_header non_underline">{{$t('dashboard_admin.logic_editor_tab')}}</div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.logic_editor'),
                      componentType: 'LogicEditor_v2',
                    })
                  "
                  >{{$t('dashboard_admin.logic_editor')}}</span
                >
                <div class="menu_text">
                  {{$t('dashboard_admin.logic_editor_des')}}
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.task_editor'),
                      componentType: 'TaskEditor',
                    })
                  "
                  >{{$t('dashboard_admin.task_editor')}}</span
                >
                <div class="menu_text">
                  {{$t('dashboard_admin.task_editor_des')}}
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.notification_editor'),
                      componentType: 'NotificationEditor',
                    })
                  "
                  >{{$t('dashboard_admin.notification_editor')}}</span
                >
                <div class="menu_text">
                  {{$t('dashboard_admin.notification_editor_des')}}
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.process_editor'),
                      componentType: 'ProcessEditor',
                    })
                  "
                >{{$t('dashboard_admin.process_editor')}}</span
                >
                <div class="menu_text">
                  {{$t('dashboard_admin.process_editor_des')}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- columns -->
        //
        <div class="menu">
          <div class="icon icon_source"></div>
          <div>
            <div class="menu_header non_underline">
              {{$t('dashboard_admin.bl_editor')}}
            </div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span class="column_header"
                      @click=
                        "addMainTab({
                        name: $t('dashboard_admin.bl_editor'),
                        componentType: 'BIEditor',
                      })">
                  {{ $t('dashboard_admin.bi_editor')}}
                </span>
                  <div class="menu_text">
                    {{$t('dashboard_admin.bl_editor_des')}}
                  </div>
              </div>
            </div>
          </div>
        </div>
        //
        <div class="menu">
          <div class="icon icon_document"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: $t('dashboard_admin.report_editor'),
                  componentType: 'ReportEditor',
                })
              "
            >
              {{$t('dashboard_admin.report_editor')}}
            </div>
            <div class="menu_text">
              {{$t('dashboard_admin.report_editor_des')}}
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_map"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({ name: $t('dashboard_admin.map_editor'), componentType: 'MapEditor' })
              "
            >
              {{$t('dashboard_admin.map_editor')}}
            </div>
            <div class="menu_text">
              {{$t('dashboard_admin.map_editor_des')}}
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_integration"></div>
          <div>
            <div class="menu_header non_underline">{{$t('dashboard_admin.integration')}}</div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.etl_editor'),
                      componentType: 'EtlEditor',
                    })
                  "
                  >{{$t('dashboard_admin.etl_editor')}}</span
                >
                <div class="menu_text">
                  {{$t('dashboard_admin.etl_editor_des')}}
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.address_editor'),
                      componentType: 'AddressService',
                    })
                  "
                  >{{$t('dashboard_admin.address_editor')}}</span
                >
                <div class="menu_text">
                  {{$t('dashboard_admin.address_editor_des')}}
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.data_editor'),
                      componentType: 'Databus',
                    })
                  "
                  >{{$t('dashboard_admin.data_editor')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span class="column_header"
                      @click="
                    addMainTab({
                      name: $t('dashboard_admin.api_service'),
                      componentType: 'ApiService',
                    })">
                  {{ $t('dashboard_admin.api_service') }}
                </span>
                -                <div class="menu_text">
                {{ $t('dashboard_admin.api_service_des') }}
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_sync"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: $t('dashboard_admin.sync_editor'),
                  componentType: 'SyncService',
                })
              "
            >
              {{$t('dashboard_admin.sync_editor')}}
            </div>
            <div class="menu_text">
              {{$t('dashboard_admin.sync_editor_des')}}
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_logs"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: $t('dashboard_admin.activity_editor'),
                  componentType: 'ActivityService',
                })
              "
            >
              {{$t('dashboard_admin.activity_editor')}}
            </div>
            <div class="menu_text">{{$t('dashboard_admin.activity_editor_des')}}</div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_settings"></div>
          <div>
            <div class="menu_header">{{$t('dashboard_admin.system_editor')}}</div>
            <div class="menu_text">{{$t('dashboard_admin.system_editor_dev')}}</div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_monitoring"></div>
          <div>
            <div class="menu_header non_underline">
              {{$t('dashboard_admin.monitoring')}}
            </div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.nginx'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/A4_qnTv7z/web?orgId=1&refresh=10s`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.nginx')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.vm'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/e0ESDrZ7z/hardware?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.vm')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.postgresql'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/-QLZ8gB7z/postgresql?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.postgresql')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.kafka'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/HD8pcdM7k/kafka?orgId=1&refresh=1m`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.kafka')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.redis'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/DiEBNrW7z/redis?orgId=1&refresh=30s`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.redis')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.airflow'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/s4WiSDMnk/airflow-cluster?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.airflow')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.dags'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/XCPzIDM7z/airflow-dag?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.dags')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.log_service'),
                      componentType: 'LogService',
                    })
                  "
                  >{{$t('dashboard_admin.log_service')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column" v-show="$config.project === 'GjiMurmansk'">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.sys_acces'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/Zjv30F04z/hardware-copy?orgId=1`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.sys_acces')}}</span
                >
                <div class="menu_text"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_api"></div>
          <div style="width: 100%">
            <div class="menu_header non_underline">
              {{$t('dashboard_admin.tech_document')}}
            </div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.api'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/swagger/index.html`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.api')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.plagin'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/plugins/index.xhtml`,
                      },
                    })
                  "
                  >{{$t('dashboard_admin.plugin')}}</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="addMainTab({
                    name: $t('dashboard_admin.service'),
                    componentType: 'ChangeLog',
                  })"
                  >{{$t('dashboard_admin.service')}}</span
                >
                <div class="menu_text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </el-scrollbar> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'dashboard_admin',
  inject: ['addMainTab'],
  methods: {
    onChangeLang (event) {
      let lang = event
      console.log({ lang })

      import(`../../locale/${lang}.json`).then((locale) => {
        Vue.prototype.$locale = locale
      })
    }
  },
  computed: {
    langs () {
      return [
        {
          value: 'ru',
          label: this.$t(`dashboard_admin.ru`)
        }, {
          value: 'en',
          label: this.$t(`dashboard_admin.en`)
        }, {
          value: 'id',
          label: this.$t(`dashboard_admin.id`)
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style/_dashboardAdminVariables";
.dashboard_admin {
  background-color: $main_background;
  // height: 100%;
  box-sizing: border-box;

  & .wrapper {
    background: $background_containter;
    height: 100%;
    width: 65%;
    margin: auto;
  }
  & .header {
    background: url("./resources/images/background.jpg") no-repeat;
    height: 155px;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;
    // position: sticky;
    // top: 0;
    background-size: cover;
  }
  & .name_header {
    color: #fff;
    font-size: 36px;
    line-height: 36px;
    display: inline-block;
  }
  & .logo_header {
    background: url("./resources/icons/logo_accent.svg") no-repeat;
    height: 32px;
    width: 165px;
    float: right;
  }
  & .container {
    // height: calc(100vh - 180px);
    // top: -65px;

    & .menu {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;
      margin-left: 50px;
      margin-right: 50px;
      min-height: 100px;
    }
    & .menu:last-child {
      margin-bottom: 0;
    }
    & .menu_text_columns {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & .menu_column {
        flex-basis: 45%;
        padding-left: 15px;
        margin-bottom: 20px;
      }
      & .column_header {
        color: $menu_column_color;
        font-size: $menu_text_fontSize;
        line-height: $menu_text_lineHeight;
        display: inline-block;
        position: relative;
        margin-bottom: 5px;
      }
      & .column_header:before {
        content: ".";
        font-size: 33px;
        position: absolute;
        left: -14px;
        top: -8px;
      }
      & .column_header:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    & .icon {
      height: 84px;
      margin-right: 24px;
      min-width: 84px;

      &.icon_data {
        background: url("./resources/icons/data.svg") no-repeat;
      }
      &.icon_interface {
        background: url("./resources/icons/interface.svg") no-repeat;
      }
      &.icon_acess {
        background: url("./resources/icons/acess.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_source {
        background: url("./resources/icons/source.svg") no-repeat;
      }
      &.icon_document {
        background: url("./resources/icons/document.svg") no-repeat;
      }
      &.icon_map {
        background: url("./resources/icons/map.svg") no-repeat;
      }
      &.icon_engineering {
        background: url("./resources/icons/engineering.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_integration {
        background: url("./resources/icons/integration.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_sync {
        background: url("./resources/icons/sync.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_logs {
        background: url("./resources/icons/logs.svg") no-repeat;
      }
      &.icon_settings {
        background: url("./resources/icons/settings.svg") no-repeat;
      }
      &.icon_monitoring {
        background: url("./resources/icons/monitoring.svg") no-repeat;
      }
      &.icon_api {
        background: url("./resources/icons/api.svg") no-repeat;
      }
    }
  }
  & .menu_header {
    color: $menu_header_color;
    font-size: $menu_header_fontSize;
    line-height: $menu_header_lineHeight;
    margin-bottom: 8px;
  }
  & .menu_header:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  & .menu_text {
    color: $menu_text_color;
    font-size: $menu_text_fontSize;
    line-height: $menu_text_lineHeight;
  }
  & .non_underline {
    text-decoration: none !important;
    cursor: default !important;
  }
  & .locale-changer {
    width: 150px;
    position: absolute;
    top:100px;
    right: 35px;
    color: $menu_column_color;
  }
  & .locale-changer_title {
    display: inline-block;
    margin-bottom: 8px;
  }
}
</style>
