<template>
  <div>
    <el-select
      v-model="selectedAddress"
      :style="`width: calc(100% - ${addButtonVisibility ? '40px' : '20px'})`"
      ref="field"
      :disabled="isDisabled"
      filterable
      clearable
      remote
      value-key="key"
      :size="size"
      :loading="loading"
      :remote-method="search"
      :placeholder="placeholder"
      @focus="setDefaultAddress"
      @clear="clearSelectedAddress"
    >
      <i slot="prefix" class="el-icon-search" style="margin-left: 5px; font-weight: bold"></i>
      <el-option
        v-for="item in searchOptions"
        :key="item.key"
        :label="getAddressLabel(item)"
        :value="item"
        class="el-option-content">
        <div class="el-option-text" @click="changeSelected(item); addAddress(item)">
          {{ getAddressLabel(item) }}
        </div>
        <div class="el-option-buttons">
          <el-button type="text" size="small" class="el-option-button" @click="changeSelected(item, true)">
            <i class="el-icon-setting el-icon--left"></i>
            {{ $locale.address_service.button.specify }}
          </el-button>
        </div>
      </el-option>
    </el-select>
    <i
      class="el-icon-plus"
      style="margin-left: 5px; font-weight: bold; cursor:pointer; color: #409EFF;"
      v-show="addButtonVisibility"
      @click="addAddress(selectedAddress)"></i>
    <i
      v-if="!isDisabled"
      :class="{'el-icon-arrow-down': !showAddressObjects, 'el-icon-arrow-up': showAddressObjects}"
      style="margin-left: 5px; font-weight: bold; cursor:pointer;"
      @click="showAddressObjects = !showAddressObjects"
    ></i>
    <address-objects
      :is-active.sync="showAddressObjects"
      :dropdown-width="dropdownWidth"
      :level="level"
      :type="type"
      :required-level="requiredLevel"
      :selected-address="selectedAddress"
      @set-address="setSelectedAddress"
      style="margin-top: 5px"
    ></address-objects>
    <div
      v-for="(item, index) in selectedAddresses"
      :key="item.key"
      style="width: calc(100% - 20px)"
    >
      <slot name="address_label" :deleteAddress="deleteAddress" :index="index" :item="item" :getAddressLabel="getAddressLabel">
        <i
          :class="'el-icon-close'"
          style="font-size: 1.5em; vertical-align: sub; margin-left: 5px; cursor:pointer;"
          @click="deleteAddress(index)"
        ></i>
        <span style="margin-left: 5px;">{{ getAddressLabel(item) }}</span>
      </slot>
    </div>
  </div>
</template>

<script>

  import AddressObjects from './AddressObjects'
  import { generateGuid } from '../../../../helpers'

  export default {
    name: 'gar_address_multi_field',
    components: { AddressObjects },
    props: {
      size: {
        type: String
      },
      level: {
        type: Number,
        default: 11
      },
      placeholder: {
        type: String
      },
      searchLimit: {
        type: Number,
        default: 50
      },
      value: {
        type: Array
      },
      requiredLevel: {
        type: String
      },
      defaultAddress: {
        type: String
      },
      initialDropdownWidth: {
        type: Number
      },
      type: {
        type: String,
        default: 'administrative'
      },
      isDisabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        selectedAddresses: this.value || [],
        selectedAddress: {},
        searchOptions: [],
        showAddressObjects: false,
        dropdownWidth: 100,
        loading: false
      }
    },
    computed: {
      addButtonVisibility () {
        // return false
        return Object.keys(this.selectedAddress || {}).length !== 0
      }
    },

    watch: {
      value: {
        handler (newValue, oldValue) {
          if (newValue.length === oldValue.length) { return }
          let i = 0
          let guids = []
          while (i < newValue.length) {
            guids.push(generateGuid())
            i++
          }
          this.searchOptions.splice(0, this.searchOptions.length)
          i = 0
          this.searchOptions.push(...newValue.map(item => {return {
            ...item,
            id: item.address_id || item.id,
            level: item.level || this.level,
            region_code: item.region_code,
            objectid: item.objectid,
            type: this.type,
            house: (item.house && item.house.name) ? item.house.name : (item.house || null),
            room: item.flat ? (item.flat.name ? item.flat.name : item.flat) : null,
            description: item.description || null,
            name: item.name,
            key: guids[i++]
          }}))
          i = 0
          this.selectedAddresses = newValue.map(item => {return {
            ...item,
            id: item.address_id || item.id,
            level: item.level || this.level,
            region_code: item.region_code,
            objectid: item.objectid,
            type: this.type,
            house: (item.house && item.house.name) ? item.house.name : (item.house || null),
            room: item.flat ? (item.flat.name ? item.flat.name : item.flat) : null,
            description: item.description || null,
            name: item.name,
            key: guids[i++]
          }})

          //this.selectedAddresses = newValue;
          /*
          (newValue || []).forEach((item) => {
            const found = this.findAddress(item, this.searchOptions)
            if (found) {
              found.id = item.id
              found.address_id = item.id
              found.name = item.name
              found.flat = item.room
            }
          })
          */
        },
        deep: true
      },
      showAddressObjects (value) {
        if (value) {
          this.updateDropdownWidth()
        }
      }
    },

    mounted () {
      this.updateDropdownWidth()
    },

    methods: {
      deleteAddress (addressIndex) {
        this.selectedAddresses.splice(addressIndex, 1)
        this.clearSelectedAddress()
        this.$emit('input', this.selectedAddresses)
      },
      addAddress (address) {
        if (Object.keys(address || {}).length === 0) {
          return
        }
        //console.log('АДРЕС ДЛЯ ДОБАВЛЕНИЯ', address)
        if (this.findAddress(address, this.selectedAddresses)) {
          return
        }
        //console.log('добавляем')
        this.selectedAddresses.push(address)

        this.$emit('input', this.selectedAddresses)
      },
      updateDropdownWidth () {
        this.dropdownWidth = 500//this.$el.offsetWidth > 500 ? 500 : this.$el.offsetWidth - 20
      },
      setDefaultAddress () {
        if (this.defaultAddress) {
          const inputEl = this.$refs.field.$el.children[0].children[0]
          inputEl.value = this.defaultAddress
          this.search(this.defaultAddress)
        }
      },
      // проставить адрес из заполненной формы
      async setSelectedAddress (address) {
        if (Object.keys(address || {}).length === 0) {
          this.clearSelectedAddress()
          return
        }
        let option = await this.loadAddress(address) || {}
        //console.log(JSON.stringify(option))
        //this.selectedAddress = option

        this.addAddress(option)
        this.clearSelectedAddress()
      },
      // загружаем адрес в селект
      async loadAddress (address) {
        if (Object.keys(address.data || {}).length === 0 || !address.type) {
          return
        }
        let url = null
        let option = await this.getAddressData(address)

        //console.log(address, option)
        if (!this.findAddress(option, this.searchOptions)) {
          this.searchOptions.push(option)
        }

        return option
        if (!url) {
          return false
        }
      },
      async getAddressData (data) {
        const house = data.house
        const flat = data.flat
        const description = data.description
        //ищем запись среди существующих (если ввели описание/квартиру, запись с которыми уже существует)

        let url
        //ищем запись среди существующих (если ввели описание/квартиру, запись с которыми уже существует)
        data.address_id = await this.findDoubleAddress(data) ?? null
        //console.log(data)
        //в этом месте нужно запросить дополнительную информацию по адресу
        // если адрес - существующий в addresses
        if (data.address_id) {
          //адрес который введён в форму уже сущестует в таблице addresses
          url = `${this.$config.api}/garaddressservice/addresses?id=${data.address_id}`
        } else {
          // иначе надо создать
          if (data.last_selected_element.type === 'house') {
            url = `${this.$config.api}/garaddressservice/addresses/houses/${data.last_selected_element.data.id}/${this.type}`
          } else if (data.last_selected_element.type === 'flat') {
            url = `${this.$config.api}/garaddressservice/addresses/houses/${data.last_selected_element.data.previous}/${this.type}`
          } else {
            //ввели руками
            url = `${this.$config.api}/garaddressservice/addresses/address_objects/${data.last_selected_element.data.id}/${this.type}`
          }
        }

        const d = await this.$http.get(url)
        const ai = d.data
        if (!ai) {
          console.warn('address not found')
          return false
        }

        let address_info = Array.isArray(ai) ? ai[0] : ai
        let address = null
        if (address_info) {
          let house2 = house ? (house.name ? house.name : house) : null
          if ((data.last_selected_element.level === 10 && data.last_selected_element.data.id)
            || data.last_selected_element.level === 11 && data.house.id) {
            //если выбран дом из выпадашки (тогда не проставляем name)
            house2 = null
          }
          address = {
            ...address_info,
            level: data.level,
            flat: flat, //typeof flat === 'object' ? flat.name : flat,
            room: flat ? (flat.name ? flat.name : flat) : null,
            house: house2 || null,//house.name ? house.name : house,
            description: data.description,
            address_id: data.address_id,
            type: this.type,

            region_code: address_info.region_code,
            objectid: address_info.objectid
          }
        } else {
          address = {
            id: null,
            level: data.level,
            key: generateGuid(),
            flat: data.flat,
            house: data.house,
            description: data.description
          }
        }
        //console.log('ai', address_info)
        //в этом месте поидее уже все есть. Надо собрать объект для селекта и вернуть его
        return address
      },
      async findDoubleAddress (data) {
        return await this.findStrictlyDouble(data) || await this.findNotStrictlyDouble(data)
      },
      async findStrictlyDouble (data) {
        const house = data.house
        const flat = data.flat
        const description = data.description

        let url = `${this.$config.api}/garaddressservice/addresses/?level=${data.level}`
        if (data.data.id && (data.data.is_added_address || data.data.address_id)
          && ((data.level !== 10 && data.level !== 11) || ((data.level === 10 || data.level === 11) && data.data.description === data.description))
        ) {
          url += `&id=${data.data.id}`
        } else {
          if (data.data.id) {
            url += `&objectid=${data.data.id}`
          } else {
            url += `&objectid=${data.last_selected_element.data.id}`
          }
          const level = data.level

          if ((level === 10 || level === 11) && house && !house.id) {
            url += `&house=${house.name}`
          } else {
            url += `&is_null=house`
          }
          if (level === 11 && flat && !flat.id) {
            url += `&room=${flat.name}`
          } else {
            url += `&is_null=room`
          }
          if (description) {
            url += `&description=${description}`
          } else {
            url += `&is_null=description`
          }
        }
        const answer = await this.$http.get(url)
        let address_id = null
        //console.log('search address in bd', answer)
        if (answer.data.length === 1) {
          address_id = answer.data[0].id
        } else if (answer.data.length > 1) {
          console.error('Finded many addresses', url)
        }
        return address_id
      },
      async findNotStrictlyDouble (data) {
        const house = data.house
        const flat = data.flat
        const description = data.description

        let urlParams = []
        let url = `${this.$config.api}/garaddressservice/addresses`
        let level = data.level
        urlParams.push(`level=${level}`)
        // для дома возможны 2 варианта:
        // 2. дом введен руками
        //  ищем введенный руками дом у улицы
        if ((level === 10 && !house.id) || (level === 11 && !house.id)) {
          //поиск по дому
          const a = await this.$http.get(`${this.$config.api}/garaddressservice/houses/${data.last_selected_element.data.id}/${this.type}`)
          const housename = (house && house.hasOwnProperty('name')) ? house.name : house
          const flatname = (flat && flat.hasOwnProperty('name')) ? flat.name : flat
          const b = a.data.find((x) => { return x.name.substring(3) === housename && x.description === description })
          if (!b) {
            return null
          }
          if (b.is_added_address) {
            urlParams.push(`id=${b.id}`)
          } else {
            urlParams.push(`objectid=${b.id}`)
            urlParams.push(`is_null=house`)
            //urlParams.push(`level=${level}`)
            if (level === 11 && flat && !flat.id) {
              urlParams.push(`&room=${flatname}`)
            } else {
              urlParams.push(`&is_null=room`)
            }
            if (description) {
              urlParams.push(`&description=${description}`)
            } else {
              urlParams.push(`&is_null=description`)
            }
          }
        } else {
          // 1. дом выбран из списка
          //  тогда откатываем objectId на уровень улицы
          //  дом вставляем как рукописный убирая "д. " вначале
          if ((level === 10 && house.id)
            || (level === 11 && data.last_selected_element.level === 10)) {
            // дом может быть не только у улицы
            urlParams.push(`objectid=${house.previous}`)
            urlParams.push(`house=${house.name.substring(3)}`)
          }

          //urlParams.push(`level=${level}`)
          if (level === 11 && flat && !flat.id) {
            urlParams.push(`&room=${flat.name}`)
          } else {
            urlParams.push(`&is_null=room`)
          }
          if (description) {
            urlParams.push(`&description=${description}`)
          } else {
            urlParams.push(`&is_null=description`)
          }
        }
        const answer = await this.$http.get(`${url}?${urlParams.join('&')}`)
        let address_id = null
        //console.log('search address in bd', answer)
        if (answer.data.length === 1) {
          address_id = answer.data[0].id
        } else if (answer.data.length > 1) {
          console.error('Finded many addresses', url)
        }
        return address_id
      },
      async search (query) {
        this.searchOptions = []
        this.loading = true
        query = encodeURIComponent(query)
        const fiasData = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/find/${this.level}/${this.type}/${query}?limit=${this.searchLimit}`)
        fiasData.data.forEach(fiasAddress => {
          this.searchOptions.push({ ...fiasAddress, key: this.getKey(JSON.parse(fiasAddress.object_ids), fiasAddress.flat, fiasAddress.description) })
        }, this)
        this.loading = false
      },
      findAddress (address, addresses) {
        return addresses.find(function (item) {
          return item.objectid === address.objectid
            && item.house === address.house
            && item.flat === address.flat
            && item.description === address.description
          }
        )
      },
      getKey(objectIds, house, flat, description) {
        let a = '';
        const b = ['city','street','subject','administrative_area','municipal_area','locality','planning_structure','house','flat']
        const c = typeof objectIds === 'object' ? objectIds : JSON.parse(objectIds)
        //console.log(c)
        for (let i = 0; i < b.length; i++) {
          //console.log(b[i], c[b[i]])
          if (c[b[i]]) {
            a += c[b[i]] + '_'
          }
        }
        //console.log('objectIds', c, a)
        //house
        if (house) {
          if (typeof house === 'object') {
            a += '_' + house.name
          } else {
            a += '_' + house
          }
        }
        if (flat) {
          a += '_' + flat
        }
        //description
        if (description) {
          a += '_' + description
        }
        return a
      },
      // выбор адреса из выпадающего списка
      async changeSelected (address, isShowAddressObjects = false) {
        if (!address) {
          return
        }
        //const realAddress = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/${address.objectid}`)
        // address_id от сервера привязанный к house, приравниваем к id
        this.$set(address, 'id', address.address_id)
        /* Other fields */
        // По умолчанию null, на сервере проверка дублей по NULL
        this.$set(address, 'description', address.description || null)
        //this.$set(this, 'selectedAddress', address)
        this.selectedAddress = address

        if (isShowAddressObjects) {
          // Показать модальное окно адреса
          this.showAddressObjects = true
        }
      },
      clearSelectedAddress () {
        this.selectedAddress = {}
        setTimeout(() => { // Без задержки не стирает по кнопке "Уточнить" (specify) в option. Vue.js не сразу проставляет значение внутри фреймворка
          this.selectedAddress = {}
        })
        //this.$set(this, 'selectedAddress', null)
      },
      getAddressLabel (address) {
        let addressLabel = address.name || address.address
        //console.log(11, address.name, address.address)
        //console.log('address для лэйбла множественный!!', address)
        if (this.level >= 10 && address.house && addressLabel && addressLabel.search(/, д\. [0-9]+/g) === -1) {
          addressLabel += ', д. ' + ((typeof address.house === 'object' && address.house) ? address.house.name : address.house)
        }

        if (address.flat && addressLabel && !addressLabel.includes(', кв.') /* Проверить содержание flat */) {
          addressLabel += ', кв. ' + ((typeof address.flat === 'object' && address.flat) ? address.flat.name : address.flat)
        }

        if (address.description) {
          addressLabel += ', ' + address.description
        }

        return addressLabel
      }
    }
  }
</script>

<style scoped>

</style>
