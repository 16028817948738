import PluginFactory from '@/core/infrastructure/service/PluginFactory'
import Registry from '@/components/Registry/Models/Registry'
import CommandExecutor from '@/core/infrastructure/service/CommandExecutor'
import EtlImport from '@/core/infrastructure/components/EtlImportPanel/EtlImport.vue'
import EventFactory from '@/core/infrastructure/service/EventFactory'
import CardsWrapper from '@/components/Registry/CardsWrapper.vue'
import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'

export interface AnyObject {
  [prop: string]: any;
}

export interface ActionExecutorOptions {
  readonly: boolean;
  action: AnyObject;
  pluginName?: string;
  event?: AnyObject;
}

export default class ActionExecutor {
  public context: any;
  public options: ActionExecutorOptions;
  public readonly: boolean;
  public pluginName?: string;
  public action: AnyObject;
  public event?: AnyObject;
  public model?: AnyObject = {}

  public actions = {
    execute_plugin: this.executePlugin,
    open_window: this.openWindow,
    open_dashboard: this.openDashboard,
    open_report: this.openReport,
    open_document: this.openDocument,
    open_xml: this.openXml,
    print_page: this.printPage,
    autofill_fields: this.autofillFields,
    execute_command: this.executeCommand,
    open_card: this.openCard,
    save_card: this.saveCard,
    close_card: this.closeCard,
    close_dashboard: this.closeDashboard,
    open_history: this.openHistory,
    open_url: this.openUrl,
    refresh_card: this.refreshCard,
    etl_export: this.etlExport,
    refresh_replication: this.refreshReplication,
    etl_import: this.etlImport,
    open_tab_in_block: this.openTabInBlock,
    refresh_components: this.refreshComponentsWithTimeFilter,
    close_window: this.closeWindow,
    register_user: this.registerUser,
    delete_records: this.deleteRecords,
    copy_to_clipboard: this.copyToClipboard,
    complex: this.complex
  }

  constructor (
    context: any,
    options: ActionExecutorOptions,
    model?: AnyObject
  ) {
    this.context = context
    this.options = options

    this.readonly = options.readonly
    this.pluginName = options.pluginName
    this.action = options.action
    this.event = options.event

    this.model = model
  }

  static async execute (
    context: any,
    options: ActionExecutorOptions = {
      readonly: false, action: {}
    },
    model?: AnyObject
  ) {
    const actionTemp: any = options.action
    const typeTemp = actionTemp?.type
    if (!typeTemp) {
      return
    }

    const instance = new ActionExecutor(
      context,
      options,
      model
    )

    return instance.onClick()
  }

  getModel () {
    if (Object.keys(this.model || {}).length > 0) {
      let temp = this.context.getModel()
      Object.entries(temp).forEach(item => {
        if (!this.model.hasOwnProperty(item[0])) {
          this.model[item[0]] = item[1]
        }
      })

      return this.model
    }

    return this.context.getModel()
  }

  async onClick () {
    if (this.readonly) {
      console.warn('действия заблокированы')
      return
    }

    const actionType = this.action.type
    const actionFunction = this.actions[actionType]

    if (typeof actionFunction !== 'function') {
      throw new Error(`Функция для действия ${actionType} не найдена`)
    }

    let parent
    if (typeof this.context.getParentDashboard === 'function') {
      parent = this?.context?.getParentDashboard()
    } else if (typeof this.context.getCard === 'function') {
      parent = this?.context?.getCard()
    }

    await EventFactory.send(
      this.context,
      {
        eventType: 'custom_button',
        entityId: parent?.id ?? parent?.cardId,
        entityType: parent?.$options?._componentTag,
        entityName: parent?.name,
        recordId: parent?.recordId,
        objectId: parent?.registryId,
        buttonName: this.context?.text
      }
    )
    return actionFunction.call(this)
  }

  /* Функции действий */
  async executePlugin () {
    const pluginName = this.pluginName || this.action.plugin
    if (!pluginName) {
      console.log(`Plugin doesn't set`)
      return false
    }

    const plugin = await PluginFactory.build(
      this.context.$config.project,
      pluginName,
      this.context,
      this.event,
      this.getModel()
    )
    await plugin.execute()

    await this.context.refreshComponents(this.action.command.componentsGuid)

    if (('is_card_close' in this.action.command) || this.action.command.is_card_close) {
      this.closeCard2(this.action.command)
    }
  }

  openWindow () {
    this.openModalWindow()
  }

  openDashboard () {
    if (!this.action.dashboard.id) {
      return
    }

    let action = this.action
    let dashboardId

    switch (action.dashboard.dashboardIdSource) {
      case 'dashboard':
        dashboardId = action.dashboard.id
        break
      case 'source':
      case 'component':
        dashboardId = this.getModel()[action.dashboard.id]
        break
      default:
        dashboardId = action.dashboard.id
        break
    }

    if (typeof dashboardId === 'string') {
      dashboardId = parseInt(dashboardId)
    }

    let initialData = {}
    let defaults = this.getDefaultsForDashboard()
    defaults.forEach((item) => {
      initialData[item.key] = item.value
    })

    let model = Object.assign(JSON.parse(JSON.stringify(this.getModel())), initialData)

    if (action.dashboard.isNewTab) {
      const openDashboard = { dashboardId: dashboardId, name: action.dashboard.window_title, recordId: null, initialData: model }
      this.context.addMainTab({
        name: action.dashboard.newTabName || 'Новая вкладка',
        componentType: 'CardsWrapper',
        payload: {
          openDashboard,
          showBackBreadcrumb: false,
          breadcrumbByButton: action.dashboard.breadcrumbByButton,
          isOpenInNewtab: true,
          showBreadcrumbEmpty: false
        }
      })

      return
    }

    if (action.dashboard.isFullscreen) {
      this.context.openDashboardCard(dashboardId, action.dashboard.window_title, null, model)
    } else {
      if (action.dashboard.containerAlias) {
        // Открыть в контейнере
        this.openDashboardInContainer({
          dashboardId: dashboardId,
          title: action.dashboard.window_title,
          showBreadcrumbs: action.dashboard.breadcrumbByButton,
          initialData: initialData
        })
        return
      }
      if (action.dashboard.frameGuid) {
        let frame = (this.context.getDashboardComponents()[`component_${action.dashboard.frameGuid}`] || [])[0]
        if (!frame) {
          console.warn('frame not found', action.dashboard)
          return false
        }
        frame.openDashboard({
          dashboardId: dashboardId,
          title: action.dashboard.window_title,
          breadcrumb: action.dashboard.breadcrumbByButton,
          initialData: initialData
        })
        return
      }
      const h = this.context.$createElement
      let customClass = 'custom_scrollbar '
      if (action.dashboard.window_width) {
        customClass += `dashboard_window_width_${action.dashboard.window_width}`
      }
      this.context.$msgbox({
        title: action.dashboard.window_title,
        customClass: customClass,
        message: h('dashboard', {
          style: {
            height: action?.dashboard?.windowHeight || ''
          },
          props: {
            id: dashboardId,
            parentContext: this.context,
            model: model,
            msgbox: 'msgbox'
          },
          key: this.context.generateGuid()
        }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    }
  }

  openDashboardInContainer ({ dashboardId, title, showBreadcrumbs, initialData = {} }) {
    // Открыть в контейнере
    const store = this.context.getContainersStore()
    if (store) {
      // Получить контейнер по алиасу
      const container = store.getByAlias(this.action.dashboard.containerAlias)
      if (container) {
        if (!container.hasOwnProperty('properties') || Array.isArray(container.properties)) {
          this.context.$set(container, 'properties', {})
        }
        this.context.$set(container.properties, 'contentType', 'registry')
        this.context.$set(container.properties, 'showBreadcrumbs', showBreadcrumbs)

        console.log('openRegistryCardInContainer', 1, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])
        // @TODO registry_... появляется на ~5 тик - нужно разобраться. Либо поменять в v-if на v-show в новом редакторе интерфейсов
        this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => {
          console.log('openRegistryCardInContainer', 5, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])

          const containerRegistry = this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`]
          containerRegistry.openDashboardCard(
            dashboardId,
            title,
            null,
            initialData
          )
        }))))
      }
    }
  }

  openReport () {
    if (!this.action.reports.id) {
      return
    }

    const sourceFilters = []

    const sources = this.action.reports.sources || []
    for (const src of sources) {
      let filters = []

      if (src.filters.length) {
        filters = new FilterBuilder(
          src.filters,
          this.context.getModel(),
          this.context.$store,
          EComponentTypes.registry
        ).buildAsApiQl()
      }

      const component = (this.context.getDashboardComponents()[`component_${src.grid}`] || [])[0]
      if (component) {
        if (typeof component.getPayload !== 'undefined') {
          const payload = component.getPayload()
          if (payload && payload.where) {
            filters.push(...payload.where.and)
          }
        } else {
          console.warn('ActionExecutor::openReport - Не найден метод "getPayload" в компоненте: ' + component.name || src.grid)
        }
      } else {
        console.warn('ActionExecutor::openReport - Не найден компонент "' + src.grid + '" в карточке')
      }

      if (filters.length > 0) {
        sourceFilters.push({
          dataSource: src.name,
          payload: {
            where: {
              and: filters
            }
          }
        })
      }
    }

    let tabName = this.action.reports.tabName?.length > 0 ? this.action.reports.tabName : this.action.reports.name

    this.context.addMainTab({
      name: tabName,
      componentType: 'StimulsoftViewer',
      payload: {
        filename: `${this.action.reports.guid}.mrt`,
        variables: this.getDataFilters(),
        sourceFilters
      }
    })
  }

  openDocument () {
    if (!this.action.reports.id) {
      return
    }

    const componentName = this.action.reports.templateComponentId || null
    const templateRecordId = componentName
      ? this.getModel()[componentName] || null
      : null

    let tabName = this.action.reports.tabName?.length > 0 ? this.action.reports.tabName : this.action.reports.name

    let reportName =
      this.action.reports.reportName &&
      this.action.reports.reportName.length > 0
        ? this.action.reports.reportName
        : this.action.reports.name
    let attributes = this.action.reports.reportName?.match(/\{(.*?)\}/g) || []
    let dateFormat = this.action.reports.reportName?.match(/\%(.*?)\%/g) || []
    const me = this.context
    const me2 = this

    if (attributes.length > 0) {
      attributes.map(attribute => {
        attribute = attribute.replace('{', '').replace('}', '')
        if (attribute === 'current_date') {
          let date = this.context.$moment(new Date())
          if (dateFormat.length > 0) {
            let format = dateFormat[0].replace('%', '').replace('%', '')
            date = date.format(format)
            reportName = reportName.replace(`%${format}%`, '')
          }
          reportName = reportName.replace(`{${attribute}}`, date)
        } else {
          let value = this.getModel()[attribute]
          if (typeof value !== 'undefined') {
            reportName = reportName.replace(`{${attribute}}`, value)
          } else {
            reportName = reportName.replace(`{${attribute}}`, '')
          }
        }
      })
    }

    this.context.$http
      .request({
        method: 'post',
        url: `${this.context.$config.api}/reporteditor/reports/${this.action.reports.id}/document/${this.action.reports.formatType}`,
        data: {
          registry_id: this.action.reports.registryId,
          field_id: this.action.reports.assocFieldId,
          record_id: this.getModel()['id'],
          record_guid: this.getModel()['guid'],
          is_open_saved_version: this.action.reports.openSavedVersion,
          filters: this.getDataFilters(),
          alternative_sources: this.action.reports.alternativeSources ?? [],
          template_field_id: this.action.reports.templateFieldId || null,
          template_record_id: templateRecordId,
          assoc_filename: reportName
        },
        responseType: 'json'
      })
      .then((response) => {
        if (response.status !== 200) {
          return
        }

        if (this.action.reports.viewType === 'download') {
          if (this.action.reports.formatType === 'xml') {
            const url = window.URL.createObjectURL(new Blob([response.data.content], { type: 'application/xml' }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${reportName}.${this.action.reports.formatType}`)
            document.body.appendChild(link)
            link.click()
            link.remove()
          } else {
            this.context.$http
              .request({
                method: 'post',
                url: `${this.context.$config.api}/reporteditor/reports/document/download`,
                data: response.data,
                responseType: 'blob'
              })
              .then(res => {
                let formats = {
                  docx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  pdf: 'application/pdf'
                }
                const url = window.URL.createObjectURL(new Blob([res.data], { type: formats[this.action.reports.formatType] }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${reportName}.${this.action.reports.formatType}`)
                document.body.appendChild(link)
                link.click()
                link.remove()
              })
          }
        } else {
          if (this.action.reports.formatType !== 'xml') {
            this.context.addMainTab({
              name: tabName,
              componentType: 'DocumentViewer',
              payload: {
                guid: this.context.generateGuid(),
                filename: `${response.data.name}.${response.data.extension}`,
                isRegistryFile: response.data.is_registry_file,
                reportId: this.context?.action?.reports.id || this.action?.reports.id
              }
            })
          } else {
            // console.log('XmlViewer')
            this.context.addMainTab({
              name: tabName,
              componentType: 'XmlViewer',
              payload: {
                guid: this.context.generateGuid(),
                content: response.data.content
              }
            })
          }
        }
        me.refreshComponents(this.action.reports.componentsGuid)
        me2.refreshReplication(me)

        console.log('Build and opening document successful is completed!')
      })
  }

  openXml () {
    return this.openDocument()
  }

  printPage () {
    let navigationClassNames = ['el-tabs__header', 'box_userInfo', 'system_menuShadow', 'system_menuShadow1', 'el-col el-col-24']
    let blocksToToggle = []
    let hideNav = this.action.printSettings.hideInterface
    let containerNames = this.action.printSettings.blockAlias

    if (hideNav) {
      navigationClassNames.forEach(className => {
        let element = document.getElementsByClassName(className)[0]
        blocksToToggle.push(element)
      })
    }

    const containersStore = this.context.getContainersStore()
    containerNames.forEach(containerAlias => {
      let container = containersStore?.getByAlias(containerAlias)
      let containerGuid = container?.guid
      let block = containersStore.getRefByGuid(containerGuid).$refs.container
      blocksToToggle.push(block)
    })

    if (window.matchMedia('print')) {
      this.hideNavigation(blocksToToggle)
    }
    window.print()
    this.showNavigation(blocksToToggle)
  }

  async autofillFields () {
    if (this.action.autofillSettings.xref) {
      let xrefField = this.context.getDashboardComponents()[`component_${this.action.autofillSettings.xref}`][0]
      xrefField.buttonPressed = true
      xrefField.executeAutocomplete(xrefField.options.filter((el) => {
        return el.id === xrefField.localValue
      })[0], null)
    } else {
      console.error('Не выбран компонент простой ссылки. Завершите настройку в редакторе интерфейсов')
    }
  }

  async executeCommand () {
    if (!this.action.command.id) {
      return
    }

    let recordId = this.getModel()['id']

    let card
    let isGetCardExist = typeof this.context.getCard === 'function'

    if (isGetCardExist) {
      card = await this.context.getCard()
    }

    if (this.action.command.is_save_card_before_command && isGetCardExist) {
      await card.saveRecord()
    }

    try {
      await CommandExecutor.execute(
        this.context,
        this.action.command.id,
        true,
        null,
        recordId
      )
      if (this.action.command.success_text && this.action.command.success_text.length > 0) {
        this.context.$message({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: this.action.command.success_text,
          type: 'success'
        })
      }
    } catch {
      if (this.action.command.failure_text && this.action.command.failure_text.length > 0) {
        this.context.$message({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: this.action.command.failure_text,
          type: 'error'
        })
      }
    }

    if (this.action.command.is_save_card && isGetCardExist) {
      await card.saveRecord()
    }

    await this.context.refreshComponents(this.action.command.componentsGuid)
    this.closeCard2(this.action.command)
  }

  async openCard () {
    /* Подготовка данных карточки */
    const openType = this.action.card.type
    const registryId = this.action.card.registryId ?? this.getModel()[this.action.card.queryRegistry]
    const initialData = {}
    const readOnly = openType === 'read'
    const action = this.action

    if (action.card.is_save_card) {
      const savingCard = await this.context.getCard()
      await savingCard.saveRecord()
    }

    const defaults = this.getDefaultsForCard()
    defaults.forEach((item) => {
      initialData[item.key] = item.value
    })

    // открытие фрейма (пока здесь)
    if (action.card.frameGuid) {
      await this.openRegistryCardInFrame(action, readOnly, initialData)
      return
    }

    if (!registryId || !openType) {
      console.warn('wrong parameters', action.card)
      return false
    }

    let recordId = null
    let cardId = action.card.cardId

    if (action.card.queryCard) {
      cardId = this.getModel()[action.card.queryCard]
    }

    switch (action.card.cardIdSource) {
      case 'source':
        cardId = this.getModel()[action.card.cardId]
        break
    }

    const defaultRecordId = action.card.recordId
    const constantRecordId = action.card.constantRecordId
    const fieldId = action.card.fieldId ?? action.card.queryRecord

    let isQuickAddCard = null

    const me = this.context
    const me2 = this

    /* Обработка до открытия карточки */
    if (openType === 'update' || openType === 'read') {
      if (constantRecordId) {
        recordId = constantRecordId
      } else if (defaultRecordId) {
        recordId = defaultRecordId
      } else if (fieldId) {
        recordId = this.getModel()[fieldId]
      }

      if (!recordId) {
        console.warn(`recordId doesn't set = ${fieldId}, constant = ${constantRecordId}`)
        return false
      }
    } else if (openType === 'add') {
      // console.log('add card', this.action.card, 'card', card)
      isQuickAddCard = await this.isQuickAddCard(registryId)
      const outerXrefComponent = Object.values(this.context.getDashboardComponents()).find(item => (item[0] && item[0].name === fieldId))
      if (outerXrefComponent && outerXrefComponent[0].outerXrefId && this.getModel()['id']) {
        initialData[`attr_${outerXrefComponent[0].outerXrefId}_`] = this.getModel()['id']
      }
    }

    const card = (cardId) ? { id: cardId } : await this.getCardId(registryId, recordId)
    const preventUserCard = !!cardId

    /* Обработка открытия карточки */
    /* Добавление */
    if (openType === 'add') {
      if (this.action.card.isFastCard) {
        this.openQuickAddCardByCheckbox(action.card, initialData)
        return
      }

      if (isQuickAddCard && !cardId) {
        // console.log('isQuickAddCard', isQuickAddCard)
        this.openQuickAddCard(isQuickAddCard)
        return
      }

      if (this.action.card.containerAlias) {
        // Открыть в контейнере
        this.openRegistryCardInContainer({ registryId, recordId, card, initialData, readOnly, showBreadcrumbs: this.action.card.breadcrumbByButton, preventUserCard })
        return
      }

      if (this.action.card.isWindow) {
        this.openRegistryCardInWindow(card, readOnly, recordId, initialData, registryId)
        return
      }

      if (this.action.card.isNewTab) {
        const openCard = { registryId, recordId, cardId: card.id, initialData, readOnly }
        this.context.addMainTab({
          name: this.calculateCardName(this.action.card.newTabName) || 'Новая вкладка',
          componentType: 'CardsWrapper',
          payload: {
            openCard,
            showBackBreadcrumb: false,
            showCloseCardBtn: false,
            isOpenInNewtab: true,
            showBreadcrumbEmpty: false
          }
        })

        return
      }

      if (fieldId) {
        if (!this.getModel()['id']) {
          console.warn(`record has not id`)
          // return false
        }

        this.context.openRegistryCard({
          registryId,
          cardId: card.id,
          cardName: '',
          recordId,
          initialData,
          preventUserCard: preventUserCard,
          registry: {
            readonly: readOnly,
            addRecord (recordId) {
              me.$set(me.getModel(), fieldId, recordId)
              me2.refreshReplication(me)
              me.refreshComponents(action.card.componentsGuid)
            },
            updateRecord () {
              me2.refreshReplication(me)
              me.refreshComponents(action.card.componentsGuid)
            },
            cancelChanges () {
              me.refreshComponents(action.card.componentsGuid)
            }
          }
        })
        return
      }

      this.context.openRegistryCard({
        registryId,
        cardId: card.id,
        cardName: '',
        recordId,
        initialData,
        preventUserCard: preventUserCard,
        registry: {
          readonly: readOnly,
          addRecord () {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
          },
          updateRecord (recordId) {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
            if (fieldId) {
              const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === fieldId)
              if (selectField) {
                if (typeof selectField[0].reloadById === 'function') {
                  selectField[0].reloadById(recordId)
                } else if (typeof selectField[0].loadData === 'function') {
                  selectField[0].loadData()
                }
              }
            }
          },
          cancelChanges () {
            me.refreshComponents(action.card.componentsGuid)
          }
        }
      })
      return
    }

    /* Редактирование/Просмотр */
    if (openType === 'update' || openType === 'read') {
      if (this.action.card.isNewTab) {
        const openCard = { registryId, recordId, cardId: card.id, initialData, readOnly }
        this.context.addMainTab({
          name: this.calculateCardName(this.action.card.newTabName) || 'Новая вкладка',
          componentType: 'CardsWrapper',
          payload: {
            openCard,
            showBackBreadcrumb: false,
            showCloseCardBtn: false,
            isOpenInNewtab: true,
            showBreadcrumbEmpty: false
          }
        })

        return
      }

      if (this.action.card.containerAlias) {
        // Открыть в контейнере
        this.openRegistryCardInContainer({ registryId, recordId, card, initialData, readOnly, showBreadcrumbs: this.action.card.breadcrumbByButton, preventUserCard })
        return
      }

      if (this.action.card.isWindow) {
        this.openRegistryCardInWindow(card, readOnly, recordId, initialData, registryId)
        return
      }

      this.context.openRegistryCard({
        registryId,
        cardId: card.id,
        cardName: '',
        recordId,
        initialData,
        preventUserCard: preventUserCard,
        readOnly: readOnly,
        registry: {
          readonly: readOnly,
          addRecord () {
            me.refreshComponents(action.card.componentsGuid)
          },
          updateRecord (recordId) {
            if (action?.card.componentsGuid) {
              me.refreshComponents(action.card.componentsGuid)
            }
            if (fieldId) {
              const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === fieldId)
              if (selectField) {
                if (typeof selectField[0].reloadById === 'function') {
                  selectField[0].reloadById(recordId)
                } else if (typeof selectField[0].loadData === 'function') {
                  selectField[0].loadData()
                }
              }
            }
          },
          cancelChanges () {
            me.refreshComponents(action.card.componentsGuid)
          }
        }
      })
    }
  }

  calculateCardName (name) {
    let attributes = name.match(/\{{(.*?)\}}/g) || []
    let me = this
    attributes.forEach((attribute) => {
      attribute = attribute.replace('{{', '').replace('}}', '')
      let value = me.context.getRawData()[attribute] || me.context?.params?.data[attribute]
      try {
        value = JSON.parse(value)
      } catch (e) {

      }
      if (value instanceof Array) {
        value = value.map(item => item.name).join(',')
      }
      name = name.replace(`{{${attribute}}}`, value || '')
    })

    return name
  }

  async saveCard () {
    let action = this.action.saveCard

    if (this.context.inCard) {
      const card = this.context.getCard()

      try {
        await card.saveRecord()

        if (action.isCloseAfterSave) {
          action.is_card_close = true
          if (action.close_type) {
            this.closeCard2(action)
          } else {
            card.$emit('cancelChanges')
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  closeCard () {
    if (this.context.inCard) {
      const card = this.context.getCard()
      card.$emit('cancelChanges', { confirmCloseCard: card.buttonClose.confirmCloseCard, confirmCloseCardText: card.buttonClose.confirmCloseCardText })
    }
  }

  closeDashboard () {
    this.context.cancelChanges()
    if (this.context.activeTab != null) {
      if (this.context.openedCards == null) {
        this.context.closeTab(this.context.activeTab())
      }
    }
    try {
      this.context.$msgbox.close()
    } catch (e) {
    }
  }

  openHistory () {
    if (this.context.inCard) {
      const card = this.context.getCard()
      const registryId = card.recordData.object_id
      const recordId = card.recordData.id

      this.context.openTabModalWindow('History', { registryId, recordId })
    }
  }

  openUrl () {
    const openType = this.action.url.openType
    let url
    let urlAttrValue = this.getModel()[this.action.url.urlAttr]

    if (this.action.url.urlAttr && urlAttrValue) {
      url = urlAttrValue
    } else {
      url = this.action.url.url
    }

    try {
      // Проверить корректность URL
      const temp = new URL(url)
    } catch (error) {
      // URL без протокола
      if (url.substring(0, 2) !== '//') {
        // Сделать URL абсолютным
        url = '//' + url
      }
    }

    if (openType === 'newTab') {
      window.open(url, '_blank')
      return
    }
    if (openType === 'currentTab') {
      window.open(url, '_self')
      return
    }
    if (openType === 'newWindow') {
      const win = window.open(url, url, 'menubar=yes,toolbar=yes,location=yes,status=yes,resizable=yes')
    }
  }

  async refreshCard () {
    let message
    const card = typeof this.context.getCard === 'function' ? this.context.getCard().getCard() : undefined
    const dashboard = typeof this.context.getParentDashboard === 'function' ? this.context.getParentDashboard() : undefined

    if (this.action.refreshCard.warnMessage.onEdit) {
      message = this.action.refreshCard.warnMessage.onEdit
    } else if (this.action.refreshCard.warnMessage.onRead) {
      message = this.action.refreshCard.warnMessage.onRead
    }

    if (card || dashboard) {
      if (card) {
        if (message && card.isBlocked === false) {
          this.showMessageBeforeRefresh(message, card)
        } else if (message && card.isBlocked === true) {
          this.showMessageBeforeRefresh(message, card)
        } else {
          if (card.isBlocked === false) {
            await this.checkOnSaveBeforeRefresh(card)
          }
          await this.reopenCard(card)
        }
      } else {
        dashboard.loadData()
      }
    }
  }

  etlExport () {
    let fields = {}
    if (this.action.etl_export.filters) {
      this.action.etl_export.filters.map(item => {
        let value = this.context.getModel()[item.attribute]
        if (value !== null && typeof value !== 'undefined') {
          fields[item.alias] = this.context.getModel()[item.attribute]
        }
      })
    }

    this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/etleditor/export/${this.action.etl_export.task_id}`,
      responseType: 'arraybuffer',
      data: {
        payload: fields
      }
    })
      .then(response => {
        let blob = new Blob([response.data], { type: response.headers['content-type'] })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      })
  }

  async refreshReplication (me) {
    let _:{ context: any; } = this
    if (typeof me !== 'undefined') {
      _ = {
        context: me
      }
    }
    const context = _.context?.getInterfaceWrapper() || _.context

    const store = context.getContainersStore()
    if (!store) {
      return
    }

    const blockGuids = (me || this).action?.refreshReplication?.blocks
    if (!Array.isArray(blockGuids)) {
      return
    }

    const promises = []
    for (const blockGuid of blockGuids) {
      promises.push(context.refreshReplication(blockGuid))
    }

    try {
      await Promise.all(promises)
    } catch (error) {
      console.error('Ошибка тиражирования', error)
    }
  }

  /* Вспомогательные функции */

  async getCardId (registryId, recordId = null) {
    console.log('id card not set')
    let url = `${this.context.$config.api}/registryservice/registry/${registryId}/card`
    if (recordId) {
      url = `${this.context.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
    }
    const data = await this.context.$http.get(url)

    return data.data[0]
  }

  getDefaultsForCard () {
    const defaults = []

    if (this.action.card.defaults) {
      this.action.card.defaults.forEach(item => {
        if (!item.type || item.type === 'field') {
          if (this.context.getModel()[item.attribute] && item.alias) {
            defaults.push({
              key: item.alias,
              value: this.context.getModel()[item.attribute]
            })
          }
        } else if (item.type === 'registry_field') {
          if (this.getModel()[item.attribute] && item.alias) {
            defaults.push({
              key: item.alias,
              value: this.getModel()[item.attribute]
            })
          }
        } else if (item.type === 'constant' && item.alias) {
          defaults.push({
            key: item.alias,
            value: item.attribute
          })
        } else if (item.type === 'current_user') {
          defaults.push({
            key: item.alias,
            value: this.context.$store.getters['Authorization/userId']
          })
        }
      })
    }

    return defaults
  }

  async isQuickAddCard (registryId) {
    // Проверка на карточку быстрого добавления
    const registryData = new Registry({ id: registryId })
    const me = this.context
    const structure = await registryData.structure().first()
      .catch(() => {
        me.error = true
      })

    if (!structure) {
      return false
    }

    const quickAddCard = (structure.properties || []).find(item => item.id === 'quick_add_card') || {}
    if (quickAddCard.value && quickAddCard.value.card_id) {
      return quickAddCard
    } else {
      return false
    }
  }

  openQuickAddCardByCheckbox (quickAddCard, initialData = {}) {
    const h = this.context.$createElement
    let customClass = 'custom_scrollbar '
    if (quickAddCard.windowWidth) {
      customClass += `window_width_${quickAddCard.windowWidth}`
    }

    const me = this.context
    const me2 = this
    this.context.$msgbox({
      customClass: customClass,
      message: h('registry-card', {
        style: {
          height: quickAddCard.windowHeight || ''
        },
        props: {
          cardId: quickAddCard.cardId,
          registryId: quickAddCard.registryId,
          parentContext: null,
          model: {},
          quick: true,
          initialData: initialData,
          context: me,
          addMainTabInWindow: me.addMainTab
        },
        on: {
          'quick-add': async data => {
            const cardFast = await this.getFastCard(data, quickAddCard.registryId)
            me.openRegistryCard({
              registryId: quickAddCard.registryId,
              cardId: cardFast.id,
              cardName: cardFast.name,
              recordId: null,
              initialData: data,
              preventUserCard: true,
              registry: {
                addRecord (recordid) {
                  me.getModel()[quickAddCard.fieldId] = recordid
                  me.refreshComponents(quickAddCard.componentsGuid)
                  me2.refreshReplication(me)
                },
                updateRecord () {
                  me.refreshComponents(quickAddCard.componentsGuid)
                  me.refreshComponents(quickAddCard.componentsGuid)
                  me2.refreshReplication(me)
                }
              }
            })
            me.$msgbox.close()
          },
          cancelChanges () {
            me.$msgbox.close()
          }
        },
        key: this.context.generateGuid()
      }),
      showCancelButton: false,
      showConfirmButton: false,
      closeOnClickModal: false
    })
  }

  openQuickAddCard (quickAddCard, initialData = {}) {
    const h = this.context.$createElement
    let customClass = 'custom_scrollbar '
    if (quickAddCard.value.width) {
      customClass += `window_width_${quickAddCard.value.width}`
    }

    const me = this.context
    const me2 = this
    this.context.$msgbox({
      customClass: customClass,
      message: h('registry-card', {
        style: {
          height: quickAddCard.value.height || ''
        },
        props: {
          cardId: quickAddCard.value.card_id,
          registryId: this.action.card.registryId,
          parentContext: null,
          model: {},
          quick: true,
          initialData: initialData,
          addMainTabInWindow: me.addMainTab
        },
        on: {
          'quick-add': async data => {
            const cardFast = await this.getFastCard(data, me.action.card.registryId)
            me.openRegistryCard({
              registryId: me.action.card.registryId,
              cardId: cardFast.id,
              cardName: cardFast.name,
              recordId: null,
              initialData: data,
              preventUserCard: true,
              registry: {
                addRecord (recordid) {
                  me.getModel()[me.action.card.fieldId] = recordid
                  me.refreshComponents(me.action.card.componentsGuid)
                  me2.refreshReplication(me)
                },
                updateRecord () {
                  me.refreshComponents(me.action.card.componentsGuid)
                  me2.refreshReplication(me)
                }
              }
            })
            me.$msgbox.close()
          },
          cancelChanges () {
            me.$msgbox.close()
          }
        },
        key: this.context.generateGuid()
      }),
      showCancelButton: false,
      showConfirmButton: false,
      closeOnClickModal: false
    })
  }

  openRegistryCardInContainer ({ registryId, recordId, card, initialData, readOnly, showBreadcrumbs, preventUserCard = false }) {
    // Открыть в контейнере
    const store = this.context.getContainersStore()
    if (store) {
      // Получить контейнер по алиасу
      const container = store.getByAlias(this.action.card.containerAlias)
      if (container) {
        if (!container.hasOwnProperty('properties') || Array.isArray(container.properties)) {
          this.context.$set(container, 'properties', {})
        }
        this.context.$set(container.properties, 'registry', { registryId })
        this.context.$set(container.properties, 'showBreadcrumbs', showBreadcrumbs)
        this.context.$set(container.properties, 'contentType', 'registry')

        console.log('openRegistryCardInContainer', 1, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])
        // @TODO registry_... появляется на ~5 тик - нужно разобраться. Либо поменять в v-if на v-show в новом редакторе интерфейсов
        this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => {
          console.log('openRegistryCardInContainer', 5, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])

          const containerRegistry = this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`]

          let me = this.context
          let me2 = this
          let action = this.action

          containerRegistry.openRegistryCard({
            registryId,
            cardId: card.id,
            cardName: '',
            recordId,
            initialData,
            readOnly: readOnly,
            preventUserCard: preventUserCard,
            registry: {
              addRecord () {
                me.refreshComponents(action.card.componentsGuid)
                me2.refreshReplication(me)
              },
              updateRecord () {
                me.refreshComponents(action.card.componentsGuid)
                me2.refreshReplication(me)
              },
              cancelChanges () {
                me.refreshComponents(action.card.componentsGuid)
              }
            }
          })
        }))))

        // const containerRegistry = this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`]
        // console.log('openRegistryCardInContainer', 2, containerRegistry)

        // containerRegistry.openRegistryCard({
        //   registryId,
        //   cardId: card.id,
        //   cardName: '',
        //   recordId,
        //   initialData,
        //   registry: { readonly: readOnly }
        // })
      }
    }
  }

  async openRegistryCardInFrame (action, readOnly, initialData) {
    let registryId
    let recordId
    let cardId
    if (action.card.queryRegistry) {
      registryId = this.context.getModel()[action.card.queryRegistry]
      recordId = this.context.getModel()[action.card.queryRecord]
      cardId = this.context.getModel()[action.card.queryCard] || null
    } else {
      registryId = action.card.registryId
      recordId = this.context.getModel()[action.card.fieldId] || action.card.recordId
      cardId = action.card.cardId || null
    }

    if (!registryId) {
      console.warn(`registryId: ${registryId}, recordId: ${recordId}`)
      return
    }
    if (!cardId) {
      let card = await this.getCardId(registryId, recordId)
      cardId = card.id
    }

    let frame = (this.context.getDashboardComponents()[`component_${action.card.frameGuid}`] || [])[0]
    if (!frame) {
      console.warn('frame not found', action.card)
      return false
    }

    frame.openCard({
      cardId: cardId,
      registryId: registryId,
      recordId: recordId,
      initialData,
      readOnly: readOnly
    })
  }

  openRegistryCardInWindow (card, readOnly, recordId, initialData, registryId) {
    const h = this.context.$createElement
    let customClass = 'custom_scrollbar '
    const action = this.action
    if (action.card.windowWidth) {
      customClass += `window_width_${this.action.card.windowWidth}`
    }

    const me = this.context
    const me2 = this
    try {
      this.context.$msgbox.close()
    } catch (e) {}
    this.context.$msgbox({
      title: action?.card?.windowTitle || action?.card?.windowTitle,
      customClass: customClass,
      message: h('registry-card', {
        style: {
          height: action?.card?.windowHeight || ''
        },
        props: {
          cardId: card.id,
          registryId: registryId,
          readonly: readOnly,
          recordId: recordId,
          initialData: initialData,
          context: me,
          openCardInWindow: true,
          addMainTabInWindow: me.addMainTab
        },
        on: {
          cancelChanges () {
            me.$msgbox.close()
          },
          recordAdded (recordId) {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
            me.$set(me.getModel(), action.card.fieldId, recordId)
          },
          recordEdited (recordId) {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
            if (action.card.fieldId) {
              const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === action.card.fieldId)
              if (selectField) {
                if (typeof selectField[0].reloadById === 'function') {
                  selectField[0].reloadById(recordId)
                } else if (typeof selectField[0].loadData === 'function') {
                  selectField[0].loadData()
                }
              }
            }
          }
        },
        key: this.context.generateGuid()
      }),
      showCancelButton: false,
      showConfirmButton: false,
      closeOnClickModal: false
    })
  }

  async getFastCard (recordData = null, registryId) {
    const data = await this.context.$http.post(
      `${this.context.$config.api}/registryservice/registry/${registryId}/card`,
      recordData,
      { hideNotification: true }
    )
    return data.data[0]
  }

  getDataFilters () {
    let filters = []
    if (this.action.filters) {
      this.action.filters.forEach(item => {
        if (!item.type || item.type === 'field' || item.type === 'registry_field') {
          if (this.getModel()[item.attribute] && item.alias) {
            let value
            try {
              value = JSON.parse(this.getModel()[item.attribute])[0].id
            } catch (e) {
              value = this.getModel()[item.attribute]
            }
            filters.push({
              key: item.alias,
              value: value
            })
          }
        } else if (item.type === 'constant' && item.alias) {
          filters.push({
            key: item.alias,
            value: item.attribute
          })
        }
      })
    }
    return filters
  }

  showMessageBeforeRefresh (message, card) {
    this.context.$msgbox({
      title: 'Обновление экранной формы',
      message: message,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Продолжить',
      cancelButtonText: 'Отмена',
      closeOnClickModal: false,
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          try {
            if (card && card.isBlocked === false) {
              await this.checkOnSaveBeforeRefresh(card)
            }
            await this.reopenCard(card)
            done()
          } catch (e) {
            console.log(e)
          }
        } else {
          done()
        }
      }
    })
  }

  async checkOnSaveBeforeRefresh (card) {
    if (this.action.refreshCard.isSaveCardBeforeRefresh) {
      card.saveRecord()
    }
  }

  async reopenCard (card) {
    card.$emit('cancelChanges')

    await this.context.$nextTick(() => {
      this.context.openRegistryCard({
        'registryId': card.registryId,
        'cardId': card.cardId,
        // 'cardName': card.name,
        'recordId': parseInt(card.activeRecordId)
      })
    })
  }

  closeCard2 (action) {
    let depth

    switch (action.close_type) {
      case 'current_card':
        depth = 1
        break
      case 'parent_card':
        depth = 2
        break
    }

    if (!('is_card_close' in action) || !action.is_card_close) {
      return
    }

    if (this.context.activeTab != null) {
      if (this.context.openedCards == null) {
        this.context.closeTab(this.context.activeTab())
      } else {
        if (depth) {
          this.context.cancelChanges({ depth: depth })
        }
      }
    } else {
      if (depth === 1) {
        this.context.$msgbox.close()
      } else {
        this.context.getCard().context.cancelChanges({ depth: depth })
        this.context.$msgbox.close()
      }
    }
  }

  openModalWindow () {
    if (this.action.window?.guid) {
      const viewer = this.context.getViewer()
      if (!viewer) {
        console.warn('viewer not found', this.context)
        return false
      }
      const _window = viewer.windows.find((item) => item.guid === this.action.window?.guid)
      if (!_window) {
        console.warn(`window guid = ${this.action.window?.guid} - not found in windows`, viewer.windows)
        return false
      }
      viewer.modalWindow.isPopover = this.action.window.popover
      if (viewer.modalWindow.isPopover) {
        viewer.modalWindow.position = {
          x: this.event.clientX,
          y: this.event.clientY
        }
      }
      if (typeof this.context.getCard === 'function') {
        const card = this.context.getCard()
        if (card) {
          let components = []
          for (let key in _window.structure.components) {
            if (_window.structure.components.hasOwnProperty(key)) {
              components.push(_window.structure.components[key])
            }
          }
          components.forEach((item) => {
            const initialType =
              typeof item.group !== 'undefined'
                ? `${item.group}/${item.initialType}` : item.initialType
            if (!card.data[item.properties.name] && /attr_[0-9]+_/i.test(item.properties.name)) {
              if (!(item.properties.name in card.initialData) && !(item.properties.name in card.recordData) && initialType !== 'registry/xref_outer_field') {
                console.warn(`Не найдены данные в записи по компоненту с атрибутом = ${item.properties.name}`)
              }
              card.$set(card.data, item.properties.name, card.initialData[item.properties.name] || card.recordData[item.properties.name])
              if (card.excludingComponents.includes(initialType)) {
                card.excludingAttributes.push(item.properties.name)
              }
              if (initialType === 'basic/a-file' && item.properties.name) {
                card.fileAttributes.push(item.properties.name)
              }
            }
          })
        }
      }
      if (this.action.window.isClearWindowModel) {
        let clearModel = Object.assign({}, this.getModel())
        let attrs = [...new Set(this.action.window.componentsAttr.slice(0, -1).split(';'))]
        attrs.forEach((key) => {
          // @ts-ignore
          clearModel[key] = null
        })
        viewer.modalWindow.model = clearModel
      } else {
        viewer.modalWindow.model = this.getModel()
      }
      viewer.modalWindow.show = true
      viewer.modalWindow.width = `${_window.width.value}${_window.width.type}`
      viewer.modalWindow.height = `${_window.height.value}${_window.height.type}`
      viewer.modalWindow.title = undefined
      if (this.action.window?.title) {
        viewer.modalWindow.title = this.action.window.title
      }
      viewer.$nextTick(() => {
        if (!viewer.modalWindow.isPopover) {
          viewer.$refs.modal_viewer.loadInterface(_window.structure)
        } else {
          viewer.$refs.modal_viewer_popover.loadInterface(_window.structure)
        }
      })
    } else {
      console.warn('window does`t set', this.action)
    }
  }

  async etlImport () {
    let fields = []
    if (Array.isArray(this.action.etl_import.fields)) {
      fields = this.action.etl_import.fields.map(item => {
        return {
          field_id: parseInt(item.alias.match(/\d+/g).join([])),
          value: this.context.getModel()[item.attribute],
          is_key: item.isKey
        }
      })
    }

    this.action.plugin = this.action.etl_import.pluginAfterImport

    const h = this.context.$createElement
    let me = this
    let file = null
    let loader = null

    await this.context.$msgbox({
      title: 'Импорт',
      message: h(EtlImport, {
        on: {
          uploaded (uploadedFile) {
            file = uploadedFile.raw
          }
        },
        key: me.context.generateGuid()
      }),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Импорт',
      cancelButtonText: 'Закрыть',
      closeOnClickModal: false,
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          try {
            loader = me.context.$loading({ target: 'div.el-message-box', fullscreen: false })
            const form = new FormData()

            form.append('file', file)
            form.append('payload', JSON.stringify({ 'constant_fields': fields }))

            let importResponse = await me.context.$http.request({
              url: `${me.context.$config.api}/etleditor/import/${me.action.etl_import.task_id}`,
              method: 'POST',
              data: form,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            // let recordIds = importResponse.data.result.imported_ids

            if (typeof importResponse.data === 'object' && importResponse.data.result.errors.length === 0) {
              if (me.action.etl_import.commandIdAfterSuccess) {
                await CommandExecutor.execute(me.context, me.action.etl_import.commandIdAfterSuccess)
              }
            } else {
              if (typeof importResponse.data === 'object' && me.action.etl_import.commandIdAfterFailure) {
                await CommandExecutor.execute(me.context, me.action.etl_import.commandIdAfterFailure)
              }
            }

            if (me.action.plugin) {
              await me.executePlugin()
            }

            await me.context.refreshComponents(me.action.etl_import.componentsGuid)
            loader.close()
            done()
          } catch (e) {
            if (me.action.etl_import.commandIdAfterFailure) {
              await CommandExecutor.execute(me.context, me.action.etl_import.commandIdAfterFailure)
            }
            loader.close()
            done()
          }
        } else {
          done()
        }
      }
    })
  }

  getDefaultsForDashboard () {
    const defaults = []

    if (this.action.dashboard.fields) {
      this.action.dashboard.fields.forEach(item => {
        if (!item.type || item.type === 'field' || item.type === 'registry_field') {
          if (this.getModel()[item.attribute] && item.alias) {
            defaults.push({
              key: item.alias,
              value: this.getModel()[item.attribute]
            })
          }
        } else if (item.type === 'constant' && item.alias) {
          defaults.push({
            key: item.alias,
            value: item.attribute
          })
        } else if (item.type === 'current_user') {
          defaults.push({
            key: item.alias,
            value: this.context.$store.getters['Authorization/userId']
          })
        }
      })
    }

    return defaults
  }

  openTabInBlock () {
    setTimeout(() => {
      const blockWithTabs = this.context.getContainersStore().getRefByGuid(this.action.open_tab_in_block.block)
      this.context.$set(blockWithTabs, 'activeTabGuid', this.action.open_tab_in_block.tab)
      if (this.action.open_tab_in_block.executeTabAction) {
        let block = blockWithTabs.tabSettingsService.tabSettings[this.action.open_tab_in_block.tab]
        block.interactive.action = block.interactive
        blockWithTabs.$emit('click', { 'block': block, 'event': this.event })
      }
    }, 0)
  }

  private hideNavigation (toHide = []) {
    if (toHide.length) {
      toHide.forEach(el => {
        if (el) el.setAttribute('style', 'display: none')
      })
    }
  }

  private showNavigation (toShow = []) {
    if (toShow.length) {
      toShow.forEach(el => {
        if (el) {
          el.setAttribute('style', 'display: block')
          if (el.className.includes('box_userInfo')) {
            el.setAttribute('style', 'display: flex')
          }
        }
      })
    }
  }
  async refreshComponentsWithTimeFilter () {
    // получить компоненты, которые надо обновить
    const componentsUpdate: string[] = this.action.refresh_components.components
    // получить компоненты, которые находятся в карточке/дашборде
    const dashboardComponents: object[] = this.context.getDashboardComponents()
    // Список компонентов, которые можно связать с отложенными фильтрами (компонент, который выбирается в фильтрах)
    const listSupportCmp = ['registry', 'AnalyticTable', 'a-table']
    console.log('componentsUpdate', componentsUpdate)
    console.log('dashboardComponents', dashboardComponents)
    interface IGuidAndNameCmp {
      [guid: string]: {
          instanceVue: any,
          guid: string,
          attrName: string,
          nameComponents: string
        }
      }
    interface INameCmp {
      instanceVue: any,
      guid: string,
      attrName: string,
      nameComponents: string
    }
    // получить компоненты и сгруппировать ввиде гуид -> атрибут (name)...
    const guidAndNameCmp: IGuidAndNameCmp = {}
    for (const [guid, cmp] of Object.entries(dashboardComponents)) {
      const attrName = cmp[0]?.name
      let nameComponents = cmp[0]?.$options?.name
      if (attrName) {
        guidAndNameCmp[guid] = { instanceVue: dashboardComponents[guid][0], guid, nameComponents, attrName }
      }
    }
    console.log(guidAndNameCmp)
    for (const item of componentsUpdate) {
      const cmpThatFilter = guidAndNameCmp[`component_${item}`]
      if (!cmpThatFilter) {
        console.warn(`Не найден компонент ${cmpThatFilter} в карточке`)
        return
      }

      // узнать, есть ли в фильтрах отложенный фильтр setTimeFilter и получить его атрибут (name)
      const timeFilter = typeof cmpThatFilter.instanceVue.getFilterProps === 'function' ? cmpThatFilter.instanceVue.getFilterProps().filter(item => item.setTimeFilter)[0] || [] : []

      // console.log('component', component)
      if (timeFilter.length > 0 && (cmpThatFilter.nameComponents === 'registry' || cmpThatFilter.nameComponents === 'AnalyticTable')) {
        // найти компонент с которым связан отложенный фильтр и получить id выделенных строк
        let guidCmp: INameCmp = null
        for (const [guid, cmp] of Object.entries(guidAndNameCmp)) {
          if (timeFilter.attribute === cmp.attrName) {
            guidCmp = guidAndNameCmp[guid]
          }
        }
        console.log('guidCmp', guidCmp)
        if (!guidCmp) {
          console.warn(`Не найден компонент с атрибутом ${timeFilter.attribute}`)
          return
        }
        // console.log(guidCmp)

        if (!listSupportCmp.includes(guidCmp.nameComponents)) {
          console.warn(`Нельзя связать отложенный фильтр с ${guidCmp.nameComponents}`)
          return
        }

        this.timeFilterAgGrid({ dataCmpSelectedRows: guidCmp, timeFilter, cmpThatFilter })
      } else {
        console.warn(`Для компонента ${cmpThatFilter.nameComponents} нет функционала отложенный фильтр.`)
        await this.context.refreshComponents(componentsUpdate)
      }
    }
  }
  private timeFilterAgGrid ({ dataCmpSelectedRows, timeFilter, cmpThatFilter }): void {
    console.log('nameComponents', dataCmpSelectedRows.nameComponents)
    let loadGrid = null
    let gridInstance = null
    let eComponentTypes = null
    let filters = []

    let fooLoad = function () { console.warn('не удалось найти метод таблицы') }

    if (cmpThatFilter.nameComponents === 'registry') {
      loadGrid = cmpThatFilter.instanceVue.$refs.table.$refs.tableBody?.$refs.grid.load || fooLoad
      gridInstance = cmpThatFilter.instanceVue.$refs.table?.$refs.tableBody.dataSourceService || { setExternalFilters: fooLoad }
      eComponentTypes = EComponentTypes.registry
    }

    if (cmpThatFilter.nameComponents === 'AnalyticTable') {
      loadGrid = cmpThatFilter.instanceVue?.$refs.grid.load || fooLoad
      gridInstance = cmpThatFilter.instanceVue?.dataSourceService || { setExternalFilters: fooLoad }
      eComponentTypes = EComponentTypes.analyticalTable
    }

    // получить id выделенных строк
    const idSelectedRows = dataCmpSelectedRows.instanceVue.getSelectedRows().map(item => item.id)
    console.log({ idSelectedRows })

    if (idSelectedRows.length) {
      // получить последний payload
      const payload = cmpThatFilter.instanceVue.getPayload()
      let alias = timeFilter.alias
      if (timeFilter.isXref) {
        alias = alias + 'id'
      }
      // let filterWithSelectedRows = { [timeFilter.equalsType]: { [alias]: idSelectedRows.join() } }

      let filterWithSelectedRows = { in: { [alias]: idSelectedRows.join() } }

      if (payload && payload.where) {
        console.log('есть payload')
        let oldPayload = payload.where.and
        filters.push(...oldPayload)

        let found: {aliasValue?: string, index?: number } = {}
        // проверим есть ли такое же условие в фильтрах
        for (let [index, item] of oldPayload.entries()) {
          if (item.in && item.in[alias]) {
            console.warn(item.in)
            console.warn(index)

            found = { aliasValue: item.in[alias], index }
            break
          }
        }
        console.log(found)
        if (Object.keys(found).length) {
          // такое же условие есть -> Удаляет элемент по найденном индексу и вставляет новые выделенные записи
          filters.splice(found.index, 1, filterWithSelectedRows)
        } else {
          filters.push(filterWithSelectedRows)
        }
      } else {
        console.log('нет payload')
        filters.push(filterWithSelectedRows)
      }
      gridInstance.setExternalFilters(filters)
    } else {
      console.log('выеделнных записей нет')
      let filters = []
      // получить фильтр исключая отложенные фильтры
      const timeFilter = cmpThatFilter.instanceVue.getFilterProps().filter(item => !item.setTimeFilter) || []

      filters = new FilterBuilder(
        timeFilter,
        this.context.getModel(),
        this.context.$store,
        eComponentTypes
      ).buildAsApiQl()

      gridInstance.setExternalFilters(filters)
    }
    // console.log('filters', filters)
    loadGrid()
  }

  closeWindow () {
    const viewer = this.context.getViewer()
    viewer.$parent.$parent.modalWindow.show = false
  }

  async registerUser () {
    this.context.getViewer().$parent.validate(async (result: boolean) => {
      if (result) {
        try {
          await this.context.$http.post(`${this.context.$config.api}/registryservice/users/register`, this.getModel())
          // this.closeDashboard()
        } catch (e) {
          const errorKey = e.response?.data?.code || 'register_user_error'
          console.log(e.response)
          this.context.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: this.context.$t(`errors.${errorKey}`),
            type: 'error'
          })
        }
      }
    })
  }

  async deleteRecords () {
    const action = this.action.delete_records
    const context = this.context

    if (action.deleteType === 'component') {
      let component = this.context.getDashboardComponents()[`component_${action.componentGuid}`]
      component = typeof component !== 'undefined' ? component[0] : undefined
      if (component) {
        if (component.getSelectedRows().length === 0) {
          await context.$msgbox({
            title: 'Удаление записей',
            message: 'Не выбрано ни одной записи для удаления!'
          })
          return
        }
        let selectedRecords = []
        await context.$msgbox({
          title: 'Удаление записей',
          message: 'Вы действительно хотите удалить выбранные записи ?',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Продолжить',
          cancelButtonText: 'Отмена',
          closeOnClickModal: false,
          beforeClose: async (msgAction, instance, done) => {
            if (msgAction === 'confirm') {
              try {
                if (action.componentType === 'AnalyticTable/index') {
                  component.getSelectedRows().map(item => {
                    selectedRecords.push({
                      // @ts-ignore
                      id: item[action.recordId],
                      // @ts-ignore
                      object_id: item[action.registryId]
                    })
                  })
                  component.$refs.grid.gridApi.deselectAll()
                } else if (action.componentType === 'Registry') {
                  component.getSelectedRows().map(record => {
                    selectedRecords.push({
                      // @ts-ignore
                      id: record.id,
                      // @ts-ignore
                      object_id: record.object_id
                    })
                  })
                  component.$refs.table.$refs.tableBody.$refs.grid.gridApi.deselectAll()
                }
                done()
              } catch (e) {
                console.log(e)
                done()
              }
            } else {
              done()
            }
          }
        })

        if (selectedRecords.length > 0) {
          await this.context.$http.post(
            `${this.context.$config.api}/registryservice/registry/records/delete`,
            {
              records: selectedRecords
            }
          )
          this.context.refreshComponents([action.componentGuid])
        }
      } else {
        console.warn(`Не найден компонент с гуидом - ${action.componentGuid}`)
      }
    } else {
      const card = typeof this.context.getCard === 'function' ? this.context.getCard() : null
      if (card && (card.recordId && card.registryId)) {
        await context.$msgbox({
          title: 'Удаление текущей записи',
          message: 'Вы действительно хотите удалить текущую запись ?',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Продолжить',
          cancelButtonText: 'Отмена',
          closeOnClickModal: false,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              try {
                await context.$http.delete(`${context.$config.api}/registryservice/registry/${card.registryId}/records/${card.recordId}`, { hideNotification: true })
                  .then(() => {
                    context.$notify({
                      title: context.$locale.main.message.success,
                      message: context.$locale.main.message.deleted,
                      type: 'success'
                    })
                  })
                  .catch((error) => {
                    if (error.response.data.error === 'not_allowed') {
                      context.$notify.error({
                        title: context.$locale.main.message.error,
                        message: context.$locale.main.message.not_allowed
                      })
                    } else {
                      context.$notify.error({
                        title: context.$locale.main.message.error,
                        message: context.$locale.main.message.not_saved
                      })
                    }
                  })
                this.closeCard2({
                  close_type: 'current_card',
                  is_card_close: true
                })
                done()
              } catch (e) {
                console.log(e)
                done()
              }
            } else {
              done()
            }
          }
        })
      }
    }
  }

  async copyToClipboard () {
    let action = this.action.copy_to_clipboard
    let component = this.context.getDashboardComponents()[`component_${action.componentGuid}`]
    component = typeof component !== 'undefined' ? component[0] : undefined

    if (!component) {
      console.warn(`Не найден выбранный компонент с guid - ${action.componentGuid}`)
    }

    let copyingValue = component.localValue ?? component.value

    await navigator.clipboard.writeText(copyingValue)

    this.context.$message(`Значение (${copyingValue}) скопировано из компонента в буфер обмена!`)
  }

  async complex () {
    if (typeof this.action.complex === 'undefined') {
      return
    }

    if (!Array.isArray(this.action.complex.actions)) {
      return
    }

    for (const childAction of this.action.complex.actions) {
      await ActionExecutor.execute(
        this.context,
        {
          action: childAction,
          event: this.options.event,
          pluginName: this.options.pluginName,
          readonly: this.options.readonly
        },
        this.model
      )
    }
  }
}
