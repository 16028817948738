<template>
  <div>
    <p v-if="message" v-html="message"></p>
    <hr/>

    <div id="dss-login" v-if="step==0">
      <span>{{$locale.logic_editor_v2.labels.DSS_login_warning}}</span>
      <span style="padding-left: 5px">
          <el-button @click="initDSS('token')" >{{$locale.login.submit}}</el-button>
      </span>
    </div>

    <div id="list" v-loading="loading">
      <el-scrollbar wrap-style="max-height: 388px;">
        <certificate-list
          v-model="certificate"
          v-bind:certificates="certificates"
          v-if="certificates.length && step === 1"
        >
        </certificate-list>
        <info id="singning-info" v-if="step == 3 && data" v-bind:data="data"></info>
        <el-row v-if="archive && status==='success'" type="flex" justify="center">
          <el-button plain @click="download(archive)" size="small">Cкачать {{archive.name}}.{{archive.extension}}</el-button>
        </el-row>
      </el-scrollbar>
    </div>
    <el-progress :percentage="getPersentage" id="progress" v-bind:status="status"></el-progress>
    <el-row type="flex" justify="center">
      <el-button
        size="small"
        v-on:click="next"
        v-if="nextVisible"
        type="primary"
      >Далее
      </el-button>
      <el-button size="small" v-on:click="close" v-if="step<9">Отмена</el-button>
      <el-button size="small" v-on:click="close" v-if="step==9" type="success">Готово</el-button>
    </el-row>
  </div>
</template>

<script>
import CriptoHandler from './models/CriptoHandler.ts'
import DSSModel from './models/DSSModel.ts'
import CertificateList from './components/CertificateList'
import Info from './components/Info'
import CommandExecutor from '@/core/infrastructure/service/CommandExecutor'
import EDSQueryRepository from '@/core/infrastructure/domain/repository/modules/EDSQueryRepository'

export default {
  name: 'EDS',
  props: ['command', 'context', 'callback'],

  steps: [
    { i: 1, name: 'Загрузка плагина для работы с ЭП.', callback () { this.methods.initCrypto() } },
    { i: 2, name: 'Обращение к DSS', callback () { this.methods.initDSS() } }
  //   {i: 3, name: "Перечисление серверных сертификатов", callbackL: this.methods.initDSS, next: {} },
  //   {i: 4, name: "Выберие сертификат", callback: this.methods.drawCertificateList },
  //   {i: 5, name: "Выполнение команды до подписания", callback: this.methods. runBeforeSignCommand},
  //   {i: 6, name: "Проверка данных для подписания", callback: this.methods.getDataToSign },
  //   {i: 7, name: "Создание файла с подписываемыми данными", callback: this.methods.createFileWithAllInformation }, // ?
  //   {i: 8, name: "Выполнение клиентского подписания", callback: a1() },
  //   {i: 9, name: "Выполнение подписания в DSS", callback: a2() },
  //   {i: 10, name: "Разбор внещней подписи (из DSS)", callback: a2()},
  //   {i: 11, name: "Выполнение серверного подписания", callback: a2() },
  //   {i: 12, name: "Сохранение данных по подписанию", callback: a2()},
  //   {i: 13, name: "Выполнение команды после подписания", callback: a2()},
  //   {i: 14, name: "Выполнение команды в случае ошибки", callback: a2()},
  //   {i: 15, name: "Выполнение команд внутри маршрута согласования", callback: a2()},
  ],

  data () {
    return {
      certificate: {},
      message: '',
      crypto: new CriptoHandler(),
      DSS: new DSSModel(),
      loading: false,
      certificates: [],
      percentage: 0,
      step: 0,
      status: null,
      files: [],
      data: null,
      archive: null,
      nextVisible: false,
      needRollback: false,
      queryRepository: new EDSQueryRepository(),
      fileWithInfoAboutField: null,
      fileWithPrevSignedInfo: null,
      xmlToSign: undefined,
      intervals: []
    }
  },
  computed: {
    getPersentage: function () {
      return Math.ceil(this.step * 100 / 9)
    }
  },

  components: {
    CertificateList,
    Info,
    EDSQueryRepository
  },

  async mounted () {
    if (!(this.command.sign_type === 'DSS' || this.command.sign_type === 'combined')) {
      this.next()
    }
  },

  methods: {

    async setInterval (callback, time) {
      return new Promise((resolve, reject) => {
        let intervalId = 0
        this.intervals.push(
          intervalId = setInterval(() => {
            try {
              resolve(callback(intervalId))
            } catch (error) {
              reject(error)
            }
          }, time)
        )
      })
    },

    async initCrypto () {
      return this.crypto.setProvider()
    },

    async initDSS (type = 'token') {
      // type = 'token' to frontend authorize
      // type = 'code' to backend authorize

      let location = window.location

      let url = this.DSS.redirectUrl(this.command.dss_url, this.command.dss_client_id, type)
      let i = 0

      let expires = ''

      window.localStorage.removeItem('dss_code')

      let checkBack = () => {
        let code = window.localStorage.getItem('dss_code')
        return !!code
      }
      let checkFront = () => {
        let expires = window.localStorage.getItem('dss_token_expires')
        let token = window.localStorage.getItem('dss_token')
        return expires && token &&
          ((new Date(expires)).getTime() > (new Date()).getTime())
      }
      const dssTokenFromLocalStorage = () => {
        if (checkFront()) {
          this.DSS.connect(
            this.command.dss_url,
            window.localStorage.getItem('dss_token')
          )
          console.log('DSS initialized by token')
          return true
        }
        return false
      }

      const dssCodeFromLocalStorage = () => {
        if (checkBack()) {
          let code = window.localStorage.getItem('dss_code')
          this.DSS.setCode(code)
          window.localStorage.removeItem('dss_code')
          return true
        }
        return false
      }

      let immediateCheck = type !== 'code' ? type !== 'token'
        ? () => false : checkFront : checkBack
      let immediateSet = type !== 'code' ? type !== 'token'
        ? () => false : dssTokenFromLocalStorage : dssCodeFromLocalStorage

      let intervalPromise = this.setInterval(
        (intervalId) => {
          if (immediateSet()) {
            clearInterval(intervalId)
            Promise.resolve(intervalId)
            this.next()
          }
        }, 100)

      // let windowOpenPromise = this.setInterval((intervalId) => {
      //
      //   clearInterval(intervalId)
      //   Promise.resolve(intervalId)
      // }, 300)

      if (!immediateSet()) {
        console.log('New window opened ' + url)
        window.open(url, '_blank')
      }

      // await windowOpenPromise
      return intervalPromise
    },

    async next (stepData) {
      console.log('' + this.step + ': ' + this.message)

      this.step++
      this.nextVisible = false // Всегда скрываем кнопку далее, иногда по завершении шага её включаем

      try {
        if (this.step === 1) { // шаг первый - Выбор сертификата
          await this.selectCertificate()

          this.message = 'Выберите сертификат'
          this.nextVisible = true // Выбрать сертификат - нажать далее
        } else if (this.step === 2) { // Шаг второй - Выполнение команды до подписания
          try {
            this.message = 'Выполнение команды до подписания'
            if (this.command.before_command_id) {
              await CommandExecutor.execute(this.context, this.command.before_command_id, false)
              this.needRollback = true
            }
            return this.next() // безакцептно переходим к следующему шагу
          } catch (error) {
            error.commandId = this.command.before_command_id
            // eslint-disable-next-line no-throw-literal
            throw {
              message: 'Не удалось выполнить команду до подписания',
              error,
              'stage': 'commandBefore'
            }
          }
        } else if (this.step === 3) { // Шаг 3 - получение данных которые будут подписаны и их просмотр пользователем
          console.log(this.certificate)
          this.message = 'Получение данных, которые будут подписаны. Пожалуйста, подождите...'

          // Делаем запрос к бэку и получаем поля к подписанию
          let data = await this.getInfo()
          this.command.is_first_signing = data.isFirstSigning
          this.data = data.fields
          this.xmlToSign = data.xml_to_sign

          // На бэке он либо формируется, либо извлекается из предыдущей подписи
          this.fileWithPrevSignedInfo = data.fileWithInfoAboutField
          // Файловые поля отбираются в отдельный массив
          let files = []
          this.data.forEach(function (field) {
            if (field.type === 'file_field' && field.value) {
              let filesField = JSON.parse(field.value)
              filesField.forEach(function (file) {
                files.push(file)
              })
            }
          })

          this.files = files
          this.message = 'Пожалуйста, проверьте набор подписываемых данных'
          this.nextVisible = true // Тпеперь делаем кнопку "Далее" видимой.
          // Здесь пользователь изучает набор подписываемых данных прежде чем нажать кнопку "Продолжить"
        } else if (this.step === 4) { // Шаг 4 - Формируем файл со структурированными данными для подписания
          this.nextVisible = false // Здесь не будет кнопки далее
          if ((!this.command.is_multiple_sign || this.command.is_first_signing) && this.data.length > 0) {
            console.log('createFileWithAllInformation')
            // Формируем файл с данными записи для команды одиночного п.. или для первого п.. команды можественного п..
            this.fileWithInfoAboutField = await this.createFileWithAllInformation()
          } else {
            console.log('fileWithPrevSignedInfo')
            // Если мы добавляем подписанта к существующей подписи, то берем файл с данными предыдущей подписи
            this.fileWithInfoAboutField = this.fileWithPrevSignedInfo
          }

          this.files.push(this.fileWithInfoAboutField) // Добавляем файл с подписываемыми нефайловыми полями к остальным
          // Файл сформирован

          // Если нечего подписывать или сертификат у нас серверный, то мы пропускаем следующие 2 шага.
          if ((this.data.length === 0 && !this.xmlToSign?.hashByAlgorithmCertificate) ||
            this.certificate.Type === 'server') {
            // Вызов клиентского подписания в этом случае нужно пропустить.
            this.step = 7 // Перескакиваем в конец 6-го шага (next() прибавит ещё единицу)
            return this.next()
          }

          // На этом шаге получаем код для авторизации бэк-энда в DSS
          if (this.certificate.Type === 'DSS') {
            console.log('Before init DSS')
            this.message = 'Пройдите повторную аутентификацию в DSS'
            await this.initDSS('code')
            return
          }
          this.message = 'Сбор данных для подписания'
          return this.next()
        } else if (this.step === 5) {
          console.log('this.certificate', this.certificate)

          // Для клиентского подписание - формируем ЭЦП  и переходим к следующему шагу
          if (this.certificate.Type === 'client') {
            this.message = 'Подписание данных. Пожалуйста, подождите...'
            await this.signing() // Вызов клиентского подписания
          }

          // Для DSS выполняется загрузка подписываемых данных в DSS
          if (this.certificate.Type === 'DSS') {
            this.message = 'Отправляем документы на подписание'
            stepData = await this.generateArchiveAndSave(this.DSS.getCode(), 5)

            console.log('stepData::5', stepData)
            // по окончанию загрузки предупреждаем о необходимости подтверждения операции
            this.message = 'Будьте готовы подтвердить операцию в мобильном приложении. Нажмите кнопку "Далее".'
            return this.next(stepData)
          }
          this.nextVisible = true // Включим кнопку далее даже если что-то пойдет не так
          this.step = 6 // переходим на 7-й шаг (next() выполнит ещё один инкремент)
          return this.next()
        } else if (this.step === 6) { // Шаг 6 - подтверждение операции в DSS
          if (this.certificate.Type !== 'DSS') {
            return this.next() // Этот шаг только для DSS. Для других типов он выполняться не должен
          }
          console.log('6: ' + this.message)
          const data = await this.queryRepository.dssConfirmation(this.command.dss_url, this.DSS.getToken(), {
            'Resource': 'urn:cryptopro:dss:signserver:signserver',
            'ClientId': this.command.dss_client_id,
            'OperationId': stepData.operationId,
            'ClientSecret': this.command.dss_client_secret
          })
          const message = data?.Challenge?.Title?.Value || null
          if (!message) {
            // eslint-disable-next-line no-throw-literal
            throw {
              error: new Error('data?.Challenge?.Title?.Value || null'),
              message: 'Не удалось выполнить подтвержение операции в DSS',
              'stage': 'dssConfirmation'
            }
          }

          this.message = message

          const operationId = stepData.operationId

          const checkConfirmation = async () => {
            stepData = await this.queryRepository.dssConfirmation(this.command.dss_url, this.DSS.getToken(), {
              'Resource': 'urn:cryptopro:dss:signserver:signserver',
              'ClientId': this.command.dss_client_id,
              'ClientSecret': this.command.dss_client_secret,
              'ChallengeResponse': {
                'TextChallengeResponse': [
                  { 'RefId': operationId }
                ]
              }
            })

            if (stepData.IsFinal) {
              await this.next(stepData)
            } else {
              setTimeout(checkConfirmation, 1500)
            }
          }

          setTimeout(checkConfirmation, 1500)
          return
        } else if (this.step === 7) {
          this.message = (this.certificate.Type !== 'client') ? 'Идет подписание данных.' : 'Сохранение данных по подписанию'
          console.log('7: ' + this.message)
          const data = await this.generateArchiveAndSave(stepData?.AccessToken) // Вызов серверного подписания произойдет на бэке.
          this.archive = data.eds_data
          return this.next()
        } else if (this.step === 8) {
          if (this.command.after_command_id) {
            this.message = 'Выполнение команды после подписания'
            await CommandExecutor.execute(this.context, this.command.after_command_id, false)
          }
          console.log('8: ' + this.message)
          return this.next()
        } else if (this.step === 9) {
          if (this.callback && typeof this.callback === 'function') {
            this.message = 'Выполнение команд внутри маршрута согласования'
            await this.callback()
          }
          console.log('9: ' + this.message)
          this.message = 'Выполнено'
          this.status = 'success'
        }
      } catch (error) {
        console.log(error)
        let message = error.message ? error.message : 'Не удалось выполнить подписание'
        this.message = message
        this.status = 'exception'
        this.nextVisible = false

        await this.setLogs({
          'error': error.error,
          'message': error.message,
          'stage': error.stage
        })
        if (this.step >= 3) {
          try {
            if (this.command.cancel_command_id) {
              this.message = message + '<br> Выполнение команды в случае ошибки'
              this.needRollback = false
              await CommandExecutor.execute(this.context, this.command.cancel_command_id, false)
            }
          } catch (error) {
            console.log(error)
            this.message = message + '<br> Не удалось выполнить команду в случае ошибки'
            await this.setLogs({
              'error': error.error,
              'message': error.message,
              'stage': 'cancelCommand'
            })
          }
        }
      }
      this.loading = false
    },
    async selectCertificate (certificateId) {
      return new Promise(async (resolve, reject) => {
        let certs = this.certificates
        let combined = this.command.sign_type === 'combined'
        let errors = {
          'server': undefined,
          'client': undefined,
          'DSS': undefined
        }

        // добавляем серверные сертификаты в список
        if (this.command.sign_type === 'server' || combined) {
          try {
            let serverCertificates = await this.queryRepository.getServerCertificates()
            certs = serverCertificates.concat(certs)
            console.log('Server certificates')
            console.log(serverCertificates)
          } catch (e) {
            errors.server = e
            console.log('Unable to get server certificates')
          }
        }

        // добавляем клиентские сертификаты в список
        if (this.command.sign_type === 'client' || combined) {
          try {
            let clientCertificates
            await this.crypto.setProvider()
            clientCertificates = await this.crypto.getCertificates()
            certs = clientCertificates.concat(certs)
            console.log('Client Certificates')
            console.log(clientCertificates)
          } catch (e) {
            errors.client = e
            console.log('Unable to get client certificates')
          }
        }

        // Добавляем DSS сертификаты в список
        if (this.command.sign_type === 'DSS' || combined) {
          try {
            let dssCertificates = await this.DSS.listCertificates()
            certs = dssCertificates.concat(certs)
            console.log('DSS certificates')
            console.log(dssCertificates)
          } catch (e) {
            errors.DSS = e
            console.log('Unable to get DSS certificates')
          }
        }

        try {
          if (!combined) {
            if (this.command.sign_type === 'server' && errors.server !== undefined) {
              throw errors.server
            }
            if (this.command.sign_type === 'client' && errors.client !== undefined) {
              throw errors.client
            }
            if (this.command.sign_type === 'DSS' && errors.DSS !== undefined) {
              throw errors.DSS
            }
          }

          let filterList = this.command.check_certificate_fields ?? []
          let getUserData = this.$store.getters['Authorization/userAttributeData']

          // Первый перебор - получим значение полей
          filterList.forEach(function (filter) {
            // Добавим поле с промисом в котором работает запрос
            filter.userFieldValue = getUserData('attr_' + filter.userFieldId + '_')
          })
          var c = 0

          // Идем по всему списку сертификатов
          while (c++ < certs.length) {
            var add = true
            var subject = certs[c - 1]['Subject']
            var i = 0

            // Для каждого сертификата проверяем каждое условие
            while (i++ < filterList.length) {
              var filter = filterList[i - 1]
              var filedValue = await filter.userFieldValue // Здесь заросы должны отработать
              add &= subject[filter.certificateField] == filedValue // Условие - строгое равенство полей
            }

            if (add) {
              this.certificates.push(certs[c - 1])
            }
          }

          // Если нет сертификатов в списке - выкидываем ошибку
          if (this.certificates.length < 1) {
            throw { 'message': 'Отсутствуют сертификаты для ' +
              (this.command.sign_type !== 'server' ? this.command.sign_type !== 'client'
                ? 'клиентского или серверного' : 'клиентского' : 'серверного') +
                ' подписания' }
          }

          resolve(this.certificates[certificateId])
        } catch (error) {
          let stage = 'selectCertificate'
          let message = error.message ? error.message : 'Не удалось получить сертификаты для подписания'
          reject({ message, stage, error })
        }
      })
    },
    setLogs ({ error, message, stage }) {
      var registryId = this.context.getCard().getRegistryId()
      var recordId = this.context.getModel()['id']
      try {
        this.$http.post(`${this.$config.api}/cryptoservice/log/error`, { registryId, recordId, error, message, stage })
      } catch (e) {
        console.log(e)
      }
    },
    getInfo () {
      return new Promise(async (resolve, reject) => {
        try {
          let data = await this.queryRepository.getSigningFields(
            this.context.getModel()['id'],
            this.command.id,
            { parameters: { action: 'get', command: this.command, certificate: this.certificate } }
          )
          if (!data.hasOwnProperty('isFirstSigning') ||
            !data.hasOwnProperty('fields') ||
            !data.hasOwnProperty('fileWithInfoAboutField')
          ) {
            throw {
              error: data
            }
          }
          resolve(data)
        } catch (error) {
          let stage = 'getInfo'
          let message = error.message ? error.message : 'Не удалось получить подписываемые данные'
          reject({ error, message, stage })
        }
      })
    },
    signing () {
      return new Promise(async (resolve, reject) => {
        try {
          console.log('signing')
          console.log(this.files)
          console.log('Test ' + this.command.is_multiple_sign + ' : ' + this.command.is_first_signing)
          if (!this.command.is_multiple_sign || this.command.is_first_signing) {
            if (this.data.length > 0) {
              for (var i = 0; i < this.files.length; i++) {
                this.files[i].signHash = await this.getSignHash(this.files[i].hashByAlgorithmCertificate, this.certificate)
              }
            }
            console.log('signing xml')
            if (this.xmlToSign?.hashByAlgorithmCertificate) {
              const signHash = await this.getSignHash(this.xmlToSign?.hashByAlgorithmCertificate, this.certificate, true)
              this.$set(this.xmlToSign, 'signHash', signHash)
            }
            console.log('end signing xml')
          } else {
            console.log('second signature')
            for (var i = 0; i < this.files.length; i++) {
              console.log(this.files[i])
              this.files[i].signHash = await this.createCoSignHash(this.files[i].hashByAlgorithmCertificate, this.files[i].contentSigFile, this.certificate)
            }
          }
          resolve()
        } catch (error) {
          let message = error.message ? error.message : `Ошибка на этапе подписания`
          reject({ error, message, stage: 'signing' })
        }
      })
    },
    back () {
      this.step--
      this.loading = false
      this.status = null
      this.nextVisible = true
    },
    download (file) {
      let me = this
      this.$http({
        method: 'get',
        url: `${this.$config.api}/files/${this.getFilePath(file)}`,
        responseType: 'blob'
      }).then(function (response) {
        let blob = new Blob([response.data])
        me.downloadBlob(blob, me.getFileName(file))
      })
    },
    generateArchiveAndSave: function (token = undefined, stage = undefined) {
      return new Promise(async (resolve, reject) => {
        let action = stage === undefined ? 'save' : 'upload_to_dss'
        try {
          let certificateBase64 = ''

          if (this.certificate.Type === 'client') {
            certificateBase64 = await this.crypto.getCertificateBase64(this.certificate.Thumbprint)
          }

          if (this.certificate.Type === 'DSS') {
            certificateBase64 = await this.DSS.getCertificateBase64(this.certificate.Thumbprint)
          }

          let response = await this.queryRepository.generateArchiveAndSave(
            this.context.getModel()['id'],
            this.command.id,
            {
              parameters: {
                action: action,
                files: this.files,
                certificate: this.certificate,
                certificateBase64: certificateBase64,
                data: this.data,
                xml_to_sign: this.xmlToSign,
                isFirstSigning: this.command.is_first_signing,
                dss_authorization: token !== undefined ? token.toString().length > 32 ? token : undefined : undefined,
                dss_code: token !== undefined ? token.toString().length > 32 ? undefined : token : undefined
              }
            }
          )
          resolve(response)
        } catch (error) {
          let message = error.message ? error.message : 'Не удалось выполнить сохранение!'
          reject({ error, message, stage: action })
        }
      })
    },

    createCoSignHash (hashByAlgorithmCertificate, contentSigFile, certificate) {
      return new Promise(async (resolve, reject) => {
        try {
          let signHash = await this.crypto.createCoSignHash(hashByAlgorithmCertificate, contentSigFile, certificate)
          resolve(signHash)
        } catch (error) {
          let message = `КриптоПро (подписание хэша): ${error.message}`
          reject({ error, message, stage: 'createCoSignHash' })
        }
      })
    },
    getSignHash (hashByAlgorithmCertificate, certificate, xml = false) {
      return new Promise(async (resolve, reject) => {
        try {
          let signHash
          if (xml) {
            signHash = await this.crypto.signHashRaw(hashByAlgorithmCertificate, certificate)
          } else {
            signHash = await this.crypto.signHash(hashByAlgorithmCertificate, certificate)
          }
          resolve(signHash)
        } catch (error) {
          let message = `КриптоПро (подписание хэша): ${error.message}`
          reject({ error, message, stage: 'getSignHash' })
        }
      })
    },
    createFileWithAllInformation () {
      return new Promise(async (resolve, reject) => {
        try {
          let file = await this.queryRepository.createFileWithAllInformation(
            this.context.getModel()['id'],
            this.command.id,
            {
              parameters: {
                action: 'create_file',
                content: document.getElementById('singning-info').innerText,
                certificate: this.certificate
              }
            }
          )
          if (!this.certificates.server && !file.hasOwnProperty('hashByAlgorithmCertificate')) {
            throw {
              error: data
            }
          }
          resolve(file)
        } catch (error) {
          let message = 'Не удалось создать файл с выбраными полями'
          reject({ error, message, stage: 'createFileWithAllInformation' })
        }
      })
    },
    downloadBlob (blob, filename) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async close () {
      for (let i = 0; i < this.intervals.length; i++) {
        clearInterval(this.intervals[i])
      }
      if (this.command.cancel_command_id && this.status !== 'success' && this.needRollback) {
        CommandExecutor.execute(this.context, this.command.cancel_command_id, false)
          .catch((error) => {
            console.log(error)
          }).finally(() => this.$parent.doClose())
      } else {
        console.log('else')
        window.thisparent = this.$parent
        this.$parent.doClose()
      }
      console.log('close')
    }
  }
}
</script>
<style scoped>
  #singning-info hr {
    margin: 10px 10px;
  }

  #singning-info ol {
    padding-left: 20px;
  }

  #singning-info #progress {
    margin-left: 35px;
  }

  .el-scrollbar {
    opacity: 1 !important;
  }

  .el-scrollbar__bar {
    opacity: 1 !important;
  }

  .el-scrollbar__wrap {
    margin-bottom: 0px !important;
    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
  }

  .el-progress {
    margin: 20px;
  }

  #list {
    width: 100%;
    min-height: 50px;
  }

  #dss-login {
    width: 100%;
    min-height: 50px;
  }
</style>
